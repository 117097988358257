import { notify } from 'Components/common/notify';

import { isDevelopmentEnvironment, isOnDemandEnv } from 'Utils/envUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { setDomainConfig } from 'Actions/domainConfig';
import { receivedGuestCount, requestGuestCount } from 'Actions/guestCount';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { CUSTOM_HEADER } from 'Constants/constants';

export const getDomainConfig =
	(domain: any) => (dispatch: any, getState: any) => {
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/domain/`;
		const customHeaders = new Headers();
		let finalDomain = null;
		switch (true) {
			case isDevelopmentEnvironment():
				finalDomain = process.env.NEXT_PUBLIC_BASE_URL;
				break;
			case isOnDemandEnv():
				// weirdly formatted to avoid ODE replacements.
				finalDomain = `https://WWW.test-headout.com`;
				finalDomain = finalDomain.toLowerCase();
				break;
			default:
				finalDomain = domain;
		}

		customHeaders.append(CUSTOM_HEADER.ORIGIN, finalDomain);
		const requestOptions = {
			headers: customHeaders,
		};

		return fetch(url, requestOptions)
			.then(response => response.json())
			.then(json => {
				dispatch(setDomainConfig(json));
				return json;
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchGuestCount = () => (dispatch: any, getState: any) => {
	dispatch(requestGuestCount());
	const url = `${getApiCDNBaseUrlV2(getState())}/api/v1/guest-count/`;
	return fetch(url)
		.then(response => response.json())
		.then(data => {
			dispatch(receivedGuestCount(data));
		})
		.catch(err => {
			error(err);
			notify.showNetworkError(err);
		});
};
