export const deviceActions = {
	SET_USER_AGENT: 'SET_USER_AGENT',
	SET_HOST: 'SET_HOST',
};

export const setUserAgent = ({
	userAgent,
	host,
	deviceType,
	countryCode,
	isUABot,
	channel,
}: any) => ({
	deviceType,
	userAgent,
	host,
	countryCode,
	isUABot,
	channel,
	type: deviceActions.SET_USER_AGENT,
});
