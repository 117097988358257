import React from 'react';
import Link from 'next/link';

type Props = {
	url?: string;
	isExternalLink?: boolean;
	noLinkCondition?: any;
	children?: React.ReactNode;
	className?: string;
	linkProps?: any;
	href?: any;
	prefetch?: boolean;
};

const LinkWrapper = ({
	url,
	isExternalLink,
	noLinkCondition,
	children,
	className,
	href,
	linkProps,
	prefetch = false,
}: Props) => {
	switch (true) {
		case noLinkCondition:
			return <>{children}</>;
		case isExternalLink:
			return (
				<a
					rel='noopener noreferrer'
					target='_blank'
					className={className}
					href={url}
					{...linkProps}
				>
					{children}
				</a>
			);
		default:
			return (
				<Link
					prefetch={prefetch}
					href={href}
					as={url}
					className={className}
					{...linkProps}
				>
					{children}
				</Link>
			);
	}
};

export default LinkWrapper;
