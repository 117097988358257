export const ENDPOINTS = {
	CART_RECOMMENDATIONS: '/api/v1/recommendations/',
	BULK_CALENDAR: '/api/v7/tour-groups/calendar/',
	CART: '/api/v1/user-cart/',
	BULK_PRODUCT: '/api/v6/tour-groups/bulk/',
	PRODUCT: '/api/v6/tour-groups/',
	BULK_VARIANTS: '/api/v7/tour-groups/variants',
	BULK_INVENTORY: '/api/v7/tour-groups/inventories',
	UPSERT_CART: '/api/v1/user-cart/upsert/',
	FEATURE_FLAGS: '/api/v1/feature-flags/',
	REQUEST_ACCOUNT_DELETION: '/api/v2/account/request-account-deletion',
	FEEDBACK_SUBMIT: '/api/v1/feedback/submit',
} as const;
