import React from 'react';

import { css } from '@headout/pixie/css';

import Image from 'Components/common/image';

import { PAYMENT_CARD_ICONS } from 'Constants/constants';

const grid = css({
	display: 'grid',
	gridTemplateColumns: 'repeat(5, 35px)',
});

const gridItem = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 28,
	height: 28,
});

const PAYMENT_METHOD_DIM = 28;

const AcceptedMethods: React.FC<React.PropsWithChildren<unknown>> = () => {
	const PAYMENT_METHODS = Object.entries(PAYMENT_CARD_ICONS);

	return (
		<div className={grid}>
			{PAYMENT_METHODS.map(([iconName, iconURL]) => {
				return (
					<div key={iconName} className={gridItem}>
						<Image
							src={iconURL}
							alt={iconName}
							width={PAYMENT_METHOD_DIM}
							height={PAYMENT_METHOD_DIM}
							layout={'responsive'}
							placeholder={'empty'} // image is small, so no need for blur
						/>
					</div>
				);
			})}
		</div>
	);
};

export default AcceptedMethods;
