import Router from 'next/router';
import { isNumber } from 'util';

import type { ICurrency } from 'Constants/currency';
import {
	CURRENCY_SYMBOL_OVERRIDES,
	CURRENCY_SYMBOL_SHORT_OVERRIDES,
	LESSER_KNOWN_CURRENCY_CODES,
} from 'Constants/currency';

const TOP_CURRENCIES_COUNT = 6;

const MAX_PRICE_LENGTH_TO_DISPLAY = 5;

export enum TRIM_RULES {
	NONE,
	TRUNCATE_CURRENCY_SYMBOL,
	TRUNCATE_FRACTION_AND_SYMBOL,
	TRUNCATE_ALL,
}
export const getTopAndMoreCurrencies = (currencyCodesList: string[]) => [
	currencyCodesList.slice(0, TOP_CURRENCIES_COUNT),
	currencyCodesList
		.slice(TOP_CURRENCIES_COUNT)
		.sort((a, b) => a.localeCompare(b)),
];

export const getCurrencySymbolString = (code: string, localSymbol: string) =>
	code.trim() === localSymbol.trim()
		? code.trim()
		: `${code.trim()} ${localSymbol.trim()}`;

/**
 * The function checks if a given price is a valid number.
 * @param {number | null | undefined} price - The `price` parameter is a number that can also be `null`
 * or `undefined`.
 */
export const isValidPrice = (price: number | null | undefined) =>
	Number.isFinite(price);

/**
 * The `getLocalisedPrice` function formats a price value with the specified currency code and language, using the Intl.NumberFormat API.
 */
export const getLocalisedPrice = (
	price: number,
	currency: ICurrency,
	lang = 'en',
) => {
	if (!isValidPrice(price) || !currency?.code) return '';

	const isInteger = Number.isInteger(price);

	/*
	 * maximum fraction should be greater than minimum
	 * web api has default for minimum for most languages
	 * if maximum < minimum then you get RangeError
	 */

	const currencyOptions: Intl.NumberFormatOptions = {
		style: 'currency',
		currency: currency.code,
		currencyDisplay: 'code',
		useGrouping: true,
		minimumFractionDigits: isInteger ? 0 : currency.precision,
		maximumFractionDigits: isInteger ? 0 : currency.precision,
	};

	const formatter = new Intl.NumberFormat(lang, currencyOptions);

	const parts = formatter.formatToParts(price);
	// We want to keep the currencySymbol uniform across locales instead of the varying symbols that Web API provides.
	let formattedValues = parts.map(part => {
		switch (part.type) {
			case 'currency':
				return getCurrencySymbol(currency, lang, false);
			default:
				return part.value;
		}
	});
	return joinPriceParts(formattedValues, currency.code, lang);
};

export const getPricingTrimRule = (
	priceArr: { price: number; currency: ICurrency }[],
) => {
	let currentTruncateRule = TRIM_RULES.NONE;
	priceArr.forEach(priceObj => {
		Object.values(TRIM_RULES).some(trimRule => {
			if (isNumber(trimRule) && trimRule >= currentTruncateRule) {
				const isValidRule = isValidFormatWithTrim(
					priceObj.price,
					priceObj.currency,
					trimRule,
				);
				if (isValidRule) {
					currentTruncateRule = trimRule;
				}
				return isValidRule;
			}
			return false;
		});
	});
	return currentTruncateRule;
};

export const isValidFormatWithTrim = (
	price: number,
	currency: ICurrency,
	trimRule: TRIM_RULES,
) => {
	const lang = Router?.router?.query?.lang || 'en';
	const priceParts = getShortPriceParts(price, currency, lang as string);
	if (!priceParts) {
		return true;
	}
	let priceLength = 0;
	switch (trimRule) {
		case TRIM_RULES.NONE:
			priceLength = countCharactersInParts(priceParts);
			break;
		case TRIM_RULES.TRUNCATE_CURRENCY_SYMBOL:
			const partsExcludingFraction = getPricePartsExcluding(priceParts, [
				'currency',
			]);
			priceLength = countCharactersInParts(partsExcludingFraction);
			break;
		case TRIM_RULES.TRUNCATE_FRACTION_AND_SYMBOL:
			const partsExcludingFractionAndCurrency = getPricePartsExcluding(
				priceParts,
				['fraction', 'currency'],
			);
			priceLength = countCharactersInParts(
				partsExcludingFractionAndCurrency,
			);
			break;
		default:
			break;
	}
	if (priceLength > MAX_PRICE_LENGTH_TO_DISPLAY) {
		return false;
	}
	return true;
};

export const getPricePartsExcluding = (
	priceParts: Intl.NumberFormatPart[],
	excludingParts: string[],
) => {
	// if we are exlcuding fraction, we must exclude
	// decimal point as well
	if (excludingParts.includes('fraction')) {
		excludingParts.push('decimal');
	}
	return priceParts.filter(part => !excludingParts.includes(part.type));
};

export const getPriceFormatWithTrimRule = (
	price: number,
	currency: ICurrency,
	trimRule?: TRIM_RULES,
) => {
	const lang = Router?.router?.query?.lang || 'en';
	const priceParts = getShortPriceParts(price, currency, lang as string);
	if (!priceParts) return '';
	let finalPriceParts;
	switch (trimRule) {
		case TRIM_RULES.TRUNCATE_CURRENCY_SYMBOL:
			finalPriceParts = getPricePartsExcluding(priceParts, ['currency']);
			break;
		case TRIM_RULES.TRUNCATE_FRACTION_AND_SYMBOL:
			finalPriceParts = getPricePartsExcluding(priceParts, [
				'fraction',
				'currency',
			]);
			break;
		case TRIM_RULES.TRUNCATE_ALL:
			return '';
		default:
			finalPriceParts = priceParts;
	}
	return joinPriceParts(
		finalPriceParts.map(part => part.value),
		currency.code,
		lang as string,
	);
};

/*
	This function return price parts and use short-abbrevation
	notations like K, M
*/
export const getShortPriceParts = (() => {
	const memoizedValue: Record<string, Intl.NumberFormatPart[] | null> = {};
	const computeShortPriceParts = (
		price: number,
		currency: ICurrency,
		lang = 'en',
	) => {
		if (!isValidPrice(price) || !currency?.code) return null;

		const commonOptions = {
			style: 'currency' as any,
			currency: currency.code,
			currencyDisplay: 'code' as any,
			useGrouping: true,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			maximumSignificantDigits: 5,
			minimumSignificantDigits: 3,
		};

		const currencyOptions: Intl.NumberFormatOptions =
			lang.toLowerCase() === 'en'
				? {
						...commonOptions,
						notation: 'compact',
						compactDisplay: 'short',
				  }
				: commonOptions;

		const formatter = new Intl.NumberFormat(lang, currencyOptions);
		const parts = formatter.formatToParts(price);
		const currencyPart = parts.find(part => part.type === 'currency');
		if (currencyPart) {
			if (getCurrencySymbol(currency, lang, true).length < 3) {
				currencyPart!.value = getCurrencySymbol(currency, lang, true);
			} else {
				currencyPart!.value = '';
			}
		}

		let fractionPart = parts.find(part => part.type === 'fraction');
		const compact = parts.find(part => part.type === 'compact');
		if (!Number(fractionPart?.value)) {
			return getPricePartsExcluding(parts, ['fraction']);
		} else if (fractionPart && compact) {
			const franctionTensDigit = fractionPart.value?.split('')[0];
			if (Number(franctionTensDigit)) {
				fractionPart.value = franctionTensDigit;
			} else {
				return getPricePartsExcluding(parts, ['fraction']);
			}
		}

		return parts;
	};
	return (price: number, currency: ICurrency, lang = 'en') => {
		const lookupKey: string = price + currency.code + lang;
		if (!memoizedValue[lookupKey])
			memoizedValue[lookupKey] = computeShortPriceParts(
				price,
				currency,
				lang,
			);
		return memoizedValue[lookupKey];
	};
})();

function countCharactersInParts(priceParts: Intl.NumberFormatPart[]) {
	return priceParts.reduce((totalLength: number, part: any) => {
		// filter spaces
		if (!/\s/.test(part.value) && part.type !== 'decimal') {
			totalLength += part.value?.trim().length;
		}
		return totalLength;
	}, 0);
}

function joinPriceParts(parts: string[], currencyCode: string, lang: string) {
	const hasCurrencyCode = parts.includes(currencyCode);

	/* This is done to remove any extra whitespace added by the Intl API when using the 'code' option for currency display. This applies ONLY for English and if the currency Symbol is present*/
	if (lang === 'en' && !hasCurrencyCode) {
		parts = parts.filter(p => !/\s/.test(p));
	}

	return parts.join('');
}

export function getCurrencySymbol(
	currency: ICurrency,
	language: string,
	isShortPrice: boolean,
) {
	const overrides = CURRENCY_SYMBOL_OVERRIDES[currency.code];
	const shortOverrides = CURRENCY_SYMBOL_SHORT_OVERRIDES[currency.code];
	if (isShortPrice && shortOverrides && shortOverrides[language]) {
		return shortOverrides[language];
	} else if (overrides && overrides[language]) {
		return overrides[language];
	} else if (LESSER_KNOWN_CURRENCY_CODES.includes(currency.code)) {
		return currency.code;
	}
	return currency.localSymbol;
}

export const getCurrencyLocalSymbolByCode = (
	state: any,
	currencyCode: string,
) => {
	return state?.currencies?.currenciesMap?.[currencyCode]?.localSymbol;
};
