import { isAffiliate } from 'Utils/conciergeUtils';

import { HEADOUT_DOMAIN, LTD_COLLECTION_ID } from 'Constants/constants';

import { isDevelopmentEnvironment } from './envUtils';
import { getBaseUrl } from './urlUtils';

export const getWhitelistedCategoryIds = ({ host }: any) => {
	let subdomain = isDevelopmentEnvironment()
		? new URL(getBaseUrl() ?? '').host.split('.')[0] // for local development
		: host.split('.')[0];
	switch (subdomain) {
		case 'margotickets':
		case 'evanevans':
			return [String(LTD_COLLECTION_ID)];
		default:
			return null;
	}
};

export const isWhitelabel = ({ host }: { host?: string }) => {
	if (
		host?.includes('goheadout.com') ||
		host?.includes('online-tickets.co')
	) {
		return false;
	}
	switch (host) {
		case 'www.localhost.com':
		case 'localhost:4000':
		case 'www.test-headout.com':
		case 'www.stage-headout.com':
		case 'next-deimos.test-headout.internal':
		case 'next-deimos.headout.internal':
		case 'www.headout.com': {
			return false;
		}
		default:
			return true;
	}
};

export const isExternalWhitelabel = ({ host }: { host?: string }) => {
	switch (host) {
		case 'book.tickets.co.uk':
		case 'margotickets.headout.com':
		case 'evanevans.headout.com':
		case 'evanevans.test-headout.com':
		case 'barcelonapass.headout.com':
			return true;
		default:
			return false;
	}
};

export const isNonHeadoutWhitelabel = ({ host }: { host?: string }) =>
	!(host?.includes('headout') || host?.includes('localhost')) ||
	isExternalWhitelabel({ host });

export const checkNoIndex = ({
	host,
	isNoIndex,
}: {
	host: string;
	isNoIndex: boolean;
}) => host !== HEADOUT_DOMAIN || isNoIndex;

export const showCashbackInfoSection = (
	cookies: Record<string, any>,
	host: string,
) => !isAffiliate(cookies) && !isExternalWhitelabel({ host });

export const getHostPrimaryDomainName = (host?: string) =>
	host?.replace(/www.|book./g, '')?.toUpperCase();
