export const ANALYTICS_PROPERTIES = {
	NO_OF_TIME_SLOTS: 'Number Of Time Slots',
	TGID: 'Tour Group ID',
	TID: 'Tour ID',
	VID: 'Variant ID',
	FLOW_TYPE: 'Flow Type',
	MB_NAME: 'MB Name',
	MONTHS_SHOWN: 'Months Shown',
	MONTH: 'Month',
	EXPERIENCE_TIME: 'Experience Time',
	EXPERIENCE_DATE: 'Experience Date',
	RANKING: 'Ranking',
	SEAT: {
		NUMBER: 'Seat Number',
		SECTION: 'Seat Section',
		RESTRICTED: 'Restricted Seat',
		RESTRICTION_REASON: 'Restriction Reason',
		PRICE: 'Seat Price',
		ARRAY: 'Seat Array',
		DISCOUNTED: 'Discounted Seat',
		NUMBER_OF_PRICES: 'Number Of Prices',
	},
	PAYMENT_TYPE: 'Payment Type',
	PAYMENT_GATEWAY: 'Payment Gateway',
	PAYMENT_GATEWAY_TYPE: 'Payment Gateway Type',
	NO_OF_PAYMENT_OPTIONS: 'Number Of Payment Options',
	FIELD_NAME: 'Field Name',
	FIELD_VALUE: 'Field Value',
	DISCOUNT: 'Discount',
	TRIGGERED_BY: 'Triggered By',
	IS_GROUP: 'Is Group',
	PAX_TYPE: 'Pax Type',
	PAX_COUNT: 'Pax Count',
	NO_OF_TOURS: 'No of Tours',
	LEAD_TIME_DAYS: 'Lead Time Days',
	CITY: 'City',
	DISPLAY_PRICE: 'Display Price',
	DISPLAY_CURRENCY: 'Display Currency',
	PLATFORM_NAME: 'Platform Name',
	SIGNED_IN: 'Signed In',
	HSID: 'HSID',
	LANGUAGE: 'Language',
	DOMAIN: 'Domain',
	PAGE_URL: 'Page Url',
	COLLECTION_ID: 'Collection ID',
	COLLECTION_NAME: 'Collection Name',
	PRIMARY_COLLECTION_ID: 'Primary Collection ID',
	PRIMARY_COLLECTION_NAME: 'Primary Collection Name',
	PAGE_TYPE: 'Page Type',
	LAYOUT_TYPE: 'Layout Type',
	PAGE_ID: 'Page ID',
	CURRENCY: 'Currency',
	PRODUCT_NAME: 'Product Name',
	USER_WALLET_CREDITS: 'User Wallet Credits',
	CART_VALUE: 'Cart Value',
	EXPERIENCE_PRICE: 'Experience Price',
	EXPERIENCE_ID: 'Experience ID',
	TRAFFIC_ORIGIN_COUNTRY: 'Traffic Origin Country',
	CARD_ORIGIN_COUNTRY: 'Card Origin Country',
	EXPERIMENT: 'experiment',
	EXPERIMENT_VARIANT: 'Experiment Variant',
	FB_PIXEL_VALUE: 'FB Pixel Value',
	TYPE: 'Type',
	FB_PIXEL_CURRENCY: 'FB Pixel Currency',
	HAS_COMBO_VARIANTS: 'Has Combo Variants',
	NO_OF_TOUS: 'No of Tours',
	ITINERARY_ID: 'Itinerary ID',
	COUNTRY: 'Country',
	EXPERIENCE_NAME: 'Experience Name',
	CATEGORY_ID: 'Category ID',
	CATEGORY_NAME: 'Category Name',
	SUB_CATEGORY_ID: 'Sub-Category ID',
	SUB_CATEGORY_NAME: 'Sub-Category Name',
	AVERAGE_RATING: 'Average Rating',
	NO_OF_REVIEWS: 'No of Reviews',
	RATING: 'Rating',
	IS_MEDIA_PRESENT: 'Is Media Present',
	CHEVRON_SCROLLED: 'Chevron Scrolled',
	SORT_TYPE: 'Sort Type',
	SELECTED_DATE: 'Selected Date',
	SECTION: 'Section',
	CITY_CATEGORY_RANK: 'City-Category Rank',
	ACTION: 'Action',
	EXPANDED: 'Expanded',
	GOOGLE_REMARKETING: {
		DRM_CUSTOM_PARAMS: 'DRM Custom Params',
		GOOGLE_BUSINESS_VERTICAL: 'google_business_vertical',
	},
	CARD_TITLE: 'Card Title',
	BLOG_CARD_TYPE: 'Blog Card Type',
	IS_PINNED_CARD_PRESENT: 'Is Pinned Card Present',
	IS_COMPARISON_CARD_PRESENT: 'Is Comparison Card Present',
	IS_VIDEO_PRESENT: 'Is Video Present',
	BANNER_TYPE: 'ProductBanner Type',
	HSID_LOAD_TIME: 'HSID Load Time',
	EXPERIMENT_NAME: 'Experiment Name',
	COMPONENT_NAME: 'Component Name',
	LOAD_TIME: 'Load Time',
	INITIAL_STATE: 'Initial State',
	CATEGORY: 'Category',
	SUB_CATEGORY: 'Sub Category',
	COLLECTION: 'Collection',
	EXPERIENCE: 'Product',
	SEARCH_BUTTON: 'Search Button',
	SEARCH_QUERY: 'Search Query',
	ENTER_KEY: 'Enter Key',
	NUMBER_OF_SUGGESTIONS: 'Number of Suggestions',
	NUMBER_OF_CITY_SUGGESTIONS: 'Number Of City Suggestions',
	NUMBER_OF_EXPERIENCE_SUGGESTIONS: 'Number Of Experience Suggestions',
	NUMBER_OF_COLLECTION_SUGGESTIONS: 'Number Of Collection Suggestions',
	NUMBER_OF_RESULTS: 'Number Of Results',
	NUMBER_OF_CITY_EXPERIENCES: 'Number Of City Experiences',
	NUMBER_OF_CITY_COLLECTIONS: 'Number Of City Collections',
	NUMBER_OF_WORLDWIDE_EXPERIENCES: 'Number Of Worldwide Experiences',
	NUMBER_OF_WORLDWIDE_COLLECTIONS: 'Number Of Worldwide Collections',
	SUGGESTION_TYPE: 'Suggestion Type',
	POPUP_TYPE: 'Popup Type',
	SUGGESTION_STATE: 'Suggestion State',
	USER_QUERY: 'User Query',
	RESULT_TYPE: 'Result Type',
	LABEL: 'Label',
	AUTOPLAY_LOAD_TIME: 'Autoplay Load Time',
	POSITION: 'Position',
	TOUR_GROUP_ID: 'Tour Group Id',
	TOUR_ID: 'Tour Id',
	VARIANT_ID: 'Variant Id',
	BANNER: 'Banner',
	PRODUCT_CARD: 'Product Card',
	BROWSE_BY_THEMES: {
		LABEL: 'Label',
		RANK: 'Rank',
	},
	PLACEMENT: 'Placement',
	CTA_TYPE: 'CTA Type',
	COMBO_UPSELL: {
		AVERAGE_RATING: 'Upsell Average Rating',
		COMBO_DISCOUNT: 'Combo Discount',
		COUNT_PRODUCTS: 'Count Upsell Products',
		CTA_TYPE: 'CTA Type',
		CURRENCY: 'Upsell Currency',
		DISCOUNT: 'Upsell Discount',
		DROPDOWN_TYPE: 'Dropdown Type',
		ERROR_TYPE: 'Error Type',
		PRICE: 'Upsell Price',
		RANKING: 'Ranking',
		RATING_NO: 'Upsell Number Of Ratings',
		TGID: 'Upsell TGID',
		TOAST_MSG: 'Toast Message',
	},
	LIVE_PRICING_ENABLED: 'Is Live Pricing Enabled',
	PROMOCODE_DISPLAYED: 'Promocode Displayed',
	FILTER_NAME: 'Filter Name',
	ENTITY_TYPE: 'Entity Type',
	ENTITY_ID: 'Entity ID',
	IS_SELECTED: 'Is Selected',
	NUMBER_OF_EXPERIENCES: 'No Of Experiences',
	PERSONA_TYPE: 'Persona Type',
	NO_OF_CITIES: 'No Of Cities',
	NO_OF_COLLECTIONS: 'No Of Collections',
	FILTER_VALUE: 'Filter Value',
	TAB_STATE: 'Tab State',
	TOOLTIP_TYPE: 'Tooltip Type',
	CONTENT_TYPE: 'Content Type',
	EXPERIENCE_CONTENT_TYPE: 'Experience Content Type',
	SECTION_NAVIGATION_OPTION_TYPE: 'Option Type',
	TAB_NAME: 'Tab Name',
	DIRECTION: 'Direction',
	CLICK_COUNT: 'Click Count',
	CARD_TYPE: 'Card Type',
	EXPANSION_TYPE: 'Expansion Type',
	VARIANT_NAME: 'Variant Name',
	DATE_RANGE_SELECTED: 'Date Range Selected',
	DRAWER_TYPE: 'Drawer Type',
	ITINERARY_NAME: 'Itinerary Name',
	CLICK_TYPE: 'Click Type',
	STOP_NAME: 'Stop Name',
	STOP_NUMBER: 'Stop Number',
	SUB_STOP: 'Sub Stop',
	ZOOM_TYPE: 'Zoom Type',
	PERCENTAGE_VIEWED: 'Percentage Viewed',
	NUMBER_OF_RATINGS: 'Number Of Ratings',
	INFORMATION_HEADING: 'Information Heading',
	TIMESLOTS_AVAILABLE: 'Timeslots Available',
	AVAILABLE_TOURS: 'Available Tours',
	NUMBER_OF_TOURS: 'No of Tours',
	LINK: 'Link',
	NUMBER_OF_TGID_IN_CART: 'Number of TGIDs in cart',
	PIN_TYPE: 'Pin Type',
	PAGE_NUMBER: 'Page Number',
	LIMIT: 'Limit',
	TOTAL_CARDS: 'Total Cards',
	INSERT_TYPE: 'Insert Type',
	ICON_TYPE: 'Icon Type',
	EMAIL: 'Email',
	MILESTONE: 'Milestone',
	NUMBER_OF_FILTERS: 'Number Of Filters',
	LAYOUT_STYLE: 'Layout Style',
	SOURCE: 'Source',
	DESTINATION: 'Destination',
	PRICE: 'Price',
	LINK_TYPE: 'Link Type',
	IS_SESSION_RECORDED: 'Is Session Recorded',
	SCARCITY_BOOSTER_TYPE: 'Scarcity Booster Type',
	TICKETS_LEFT_BOOSTER_TOURS: 'Tickets Left Booster Tours',
	SCARCITY_BOOSTER_TOURS: 'Scarcity Booster Tours',
	TICKETS_LEFT: 'Tickets Left',
	BOOSTER_LABEL: 'Booster Label',
	TICKETS_LEFT_BOOSTER_TYPE: 'Tickets Left Booster Type',
	TICKETS_LEFT_BOOSTER: 'Tickets Left Booster',
};

export const ANALYTICS_EVENTS = {
	SELECT_PAGE_POPUP_OPENED: 'Select Page Popup Opened',
	SELECT_PAGE_POPUP_CLOSED: 'Select Page Popup Closed',
	SELECT_PAGE_POPUP_DATE_SELECTED: 'Select Page Popup Date Selected',
	UNDO_DELETION_CLICKED: 'Seatmap Page Seat Undo Clicked',
	CLEAR_ALL_CLICKED: 'Seatmap Page Clear All Seats Clicked',
	EDIT_CLICKED: 'Seatmap Page Edit Datetime Clicked',
	REDIRECT_CLICKED: 'Seatmap Page Redirect To Show Page Clicked',
	QUICK_DATE_SELECTED: 'Quick Date Selected',
	SIGN_IN_STEP_1: 'Sign In Clicked Step1',
	MIN_PAX_BANNER_VIEWED: 'Select Page ProductBanner Viewed',
	EXP_COMPONENT_LOADED: 'Experiment Component Loaded',
	EXPERIENCE_PAX_UPDATED: 'Experience Pax Updated',
	COLLECTION_CARD_CLICKED: 'Collection Card Clicked',
	COLLECTION_CARD_VIEWED: 'Collection Card Viewed',
	EXPERIENCE_CARD_VIEWED: 'Experience Card Viewed',
	EXPERIENCE_CARD_CLICKED: 'Experience Card Clicked',
	CATEGORY_TAB_CLICKED: 'Category Tab Clicked',
	CHECKOUT_PAGE: {
		PRICE_CHANGE_BANNER_CLOSED: 'Price Change Banner Closed',
		POPUP_CTA_CLICKED: 'Checkout Page Popup CTA Clicked',
		POPUP_VIEWED: 'Checkout Page Popup Viewed',
		POPUP_CLOSED: 'Checkout Page Popup Closed',
		OFFER_OPT_OUT: 'Offer Opt Out Clicked',
	},
	OFFER_OPT_OUT_POSITION: {
		ITINERARY_CARD: 'Itinerary Card',
		CHECKOUT_FORM: 'Checkout Form',
	},
	HOME_PAGE: {
		SECTION_VIEWED: 'Home Page Section Viewed',
		BANNER_CLICKED: 'Home Page ProductBanner Clicked',
		CTA_CLICKED: 'Home Page CTA Clicked',
	},
	CITY_PAGE: {
		SECTION_VIEWED: 'City Page Section Viewed',
		BANNER_CLICKED: 'City Page ProductBanner Clicked',
		CTA_CLICKED: 'City Page CTA Clicked',
	},
	SEARCH: {
		STARTED: 'Search Started',
		SUGGESTIONS_VIEWED: 'Search Suggestions Viewed',
		SUGGESTION_CLICKED: 'Search Suggestion Clicked',
		MWEB_TAPPED: 'Mweb Search Tapped',
		INITIATED: 'Search Initiated',
		RESULTS_PAGE_VIEWED: 'Search Results Page Viewed',
		RESULT_CLICKED: 'Search Result Clicked',
		SEARCH_BACK_TAPPED: 'Search Back Tapped',
	},
	SEATMAP: {
		CALENDAR_VIEWED: 'Seatmap Calendar Viewed',
		CALENDAR_MONTH_VISIBLE: 'Seatmap Calendar Month Visible',
		RESET_ZOOM_CLICK: 'Seatmap Select Reset Zoom Clicked',
		EDIT_BUTTON_CLICK: 'Seatmap Select Edit Button Clicked',
		ZOOMED: 'Seatmap Select Zoomed',
		SEAT_TYPE_CHECKBOX_CLICK: 'Seatmap Select Seat Type Checkbox Clicked',
		SEAT_SELECTED: 'Seatmap Select Seat Selected',
		SEAT_UNSELECTED: 'Seatmap Select Seat Unselected',
		SELECT_UNDO_CLICK: 'Seatmap Select Undo Clicked',
		LIST_CLICKED: 'Seatmap Price List Clicked',
		PRICE_LIST_OPENED: 'Seatmap Price List Opened',
		PRICE_LIST_CLOSED: 'Seatmap Price List Closed',
		APPLY_FILTER_CLICKED: 'Seatmap Apply Filter Clicked',
		CLEAR_FILTER_CLICKED: 'Seatmap Clear All Filters Clicked',
		SEATMAP_IFRAME_LOADED: 'Seatmap Iframe Loaded',
		PAGE_LOADED: 'Seats Page Loaded',
	},
	PROFILE_PAGE: {
		CITY: 'City',
		SIGN_OUT: 'Sign Out',
		CHAT_WITH_US: 'Chat with us',
		FAQS: 'FAQs',
		PRIVACY_POLICY: 'Privacy Policy',
		TERMS_OF_USAGE: 'Terms of Usage',
		PRICE_MARKUP: 'Price Markup',
		CURRENCY: 'Currency',
		LANGUAGE: 'Language',
		MY_BOOKINGS: 'My Bookings',
		WHATSAPP: 'Whatsapp',
	},
	APP_PUSH_PAGE: 'App Download Nudge Page Viewed',
	VIDEO: {
		AUTOPLAY_STARTED: 'Video Autoplay Started',
		AUTOPLAY_FAILED: 'Video Autoplay Failed',
		PRODUCT_CARD_IMAGE_VIEWED: 'Product Card Image Viewed',
		BANNER_VIDEO_PLAYED: 'Banner Video Played',
		PLAYED: 'Video Played',
		PAUSED: 'Video Paused',
		VIDEO_BANNER_CLICKED: 'Video Banner Clicked',
		DEAD_CLICK: 'Video Dead Click',
	},
	CAROUSEL: {
		CHEVRON_CLICKED: 'Chevron Clicked',
	},
	BROWSE_BY_THEMES: {
		VIEWED: 'Browse By Themes Section Viewed',
		THEME_TAB: 'Theme Tab Clicked',
		THEME_SELECTED: 'Theme Selected',
	},
	REVIEW_LOC: 'Translate Review Link Clicked',
	COMBO_UPSELL: {
		PAGE_VIEWED: 'Upsell Page Viewed',
		PRODUCT_SELECTED: 'Upsell Product Selected',
		CTA_CLICKED: 'Upsell Page CTA Clicked',
		DROPDOWN_CLICKED: 'Upsell Product Dropdown Clicked',
		ERROR_PAGE_VIEWED: 'Upsell Error Page Viewed',
		ERROR_TOAST_VIEWED: 'Upsell Error Toast Viewed',
	},
	CITY_PAGE_BANNER_CLICKED: 'City Page ProductBanner Clicked',
	REVIEW_STORY: {
		OPENED: 'Story Mode Opened',
		PREVIEWED: 'Reviews Media Previewed',
		CLOSED: 'Story Mode Closed',
		SCROLLED: 'Reviews Media Scrolled',
		READ_MORE: 'Read More Clicked',
	},
	PERSONA: {
		SELECTED: 'Persona Selected',
		FILTER_APPLIED: 'Persona Filter Applied',
		SECTION_VIEWED: 'Persona Page Section Viewed',
		CATEGORY_FILTER_CLICKED: 'Category Filter Clicked',
		EXPERIENCES_FILTERED: 'Experiences Filtered',
		PAGE_VIEWED: 'Persona Page Viewed',
	},
	SITEMAP: {
		CITIES_FILTERED: 'Cities Filtered',
		CITY_FILTER_APPLIED: 'City Filter Applied',
		CITY_CARD_CLICKED: 'City Card Clicked',
		COLLECTIONS_FILTERED: 'Collections Filtered',
		COLLECTION_FILTER_APPLIED: 'Collection Filter Applied',
		COLLECTION_CARD_CLICKED: 'Collection Card Clicked',
	},
	COLLECTION_PAGE_SECTION_VIEWED: 'Collection Page Section Viewed',
	IMAGE_GALLERY: {
		OPENED: 'Image Gallery Opened',
		CLOSED: 'Image Gallery Closed',
		TAB_SWITCHED: 'Image Gallery Tab Switched',
		MEDIA_CLICKED: 'Image Gallery Media Clicked',
		IMAGE_VIEWED: 'Image Viewed',
	},
	AIRPORT_TRANSFERS: {
		FIELD_CLICKED: 'Field Clicked',
		FIELD_ADDED: 'Field Added',
	},
	USER_PROFILE: {
		ICON_CLICKED: 'Account Icon Clicked',
	},
	EXPERIENCE_PAGE: {
		TRANSLATE_CONTENT_LINK_CLICKED: 'Translate Content Link Clicked',
		FLOATING_ACTION_BUTTON_CLICKED: 'Sticky Action Button Clicked',
		STICKY_SECTION_NAVIGATION_OPTION_CLICKED:
			'Sticky Action Button Option Clicked',
	},
	BLOG_SECTION: {
		BLOG_CARD_CLICKED: 'Blog Card Clicked',
		BLOG_CARD_VIEWED: 'Blog Card Viewed',
	},
	CATEGORY_SUBCATEGORY_PAGE: {
		TAB_CLICKED: 'Category Page Tab Clicked',
		COLLECTION_CARD_IMAGE_EXPANDED: 'Card Image Expanded',
		COLLECTION_CARD_DESCRIPTION_EXPANDED: 'Card Description Expanded',
		COLLECTION_CARD_CLICKED: 'Collection Card Clicked',
		CATEGORY_FILTER_CLICKED: 'Category Filter Clicked',
		CATEGORY_CTA_CLICKED: 'Category Page CTA Clicked',
		COLLECTION_CARD_DESCRIPTORS_HOVERED: 'Descriptors Hover',
		COLLECTION_CARD_IMAGE_VIEWED: 'Card Image Viewed',
	},
	PAGINATION_CLICKED: 'Pagination Clicked',
	HIGHILIGHTS_CARD_EXPANDED: 'Highlights Card Expanded',
	VARIANT_CARD_CTA_CLICKED: 'Variant Card CTA Clicked',
	ITINERARY: {
		VIEW_ITINERARY_CLICKED: 'View Itinerary Clicked',
		ITINERARY_POPUP_VIEWED: 'Itinerary Popup Viewed',
		ITINERARY_POPUP_CLOSED: 'Itinerary Popup Closed',
		ITINERARY_VARIANT_CLICKED: 'Itinerary Variant Clicked',
		BACK_BUTTON_CLICKED: 'Back Button Clicked',
		ITINERARY_CTA_CLICKED: 'Itinerary CTA Clicked',
		MAP_VIEWED: 'Map Viewed',
		MAP_ZOOMED: 'Map Zoomed',
		MAP_CLICKED: 'Map Clicked',
		STOPS_SCROLLED: 'Stops Scrolled',
	},
	RATINGS_WIDGET_CLICKED: 'Ratings Widget Clicked',
	INFORMATION_TAB_CLICKED: 'Information Tab Clicked',
	MICROSITE_PAGE_SECTION_VIEWED: 'Microsite Page Section Viewed',
	MICROSITE_PAGE_CTA_CLICKED: 'Microsite Page CTA Clicked',
	EXPERIENCE_TOUR_SELECTED: 'Experience Tour Selected',
	EXPERIENCE_DATE_SELECTED: 'Experience Date Selected',
	EXPERIENCE_TIME_SELECTED: 'Experience Time Selected',
	CALENDAR_OPENED: 'Calendar Opened',
	CALENDAR_CLOSED: 'Calendar Closed',
	TIMESLOT_DROPDOWN_OPENED: 'Timeslot Dropdown Opened',
	SHOW_MORE_REVIEWS_CLICKED: 'Show More Reviews Clicked',
	PLAN_YOUR_VISIT_TAB_VIEWED: 'Plan Your Visit Tab Viewed',
	MAP_PIN_HIGHLIGHTED: 'Map Pin Highlighted',
	MAP_LINK_CLICKED: 'Map Link Clicked',
	CATEGORY_FILTER_LIST_SHOWN: 'Category Filter List Shown',
	FILTER_APPLY_CTA_CLICKED: 'Filter Apply CTA Clicked',
	FILTER_CLEAR_CTA_CLICKED: 'Filter Clear CTA Clicked',
	FILTER_LIST_ITEM_CLICKED: 'Filter List Item Clicked',
	INFINITE_SCROLL_PAGE_LOAD: 'Infinite Scroll Page Load',
	VERTICAL_LIST_INSERT_VIEWED: 'Vertical List Insert Viewed',
	FAB_ICON_CLICKED: 'Fab Icon Clicked',
	EMAIL_ENTERED: 'Email Entered',
	EMAIL_SAVED: 'Email Saved',
	STICKY_ACTION_BUTTON_CLICKED: 'Sticky Action Button Clicked',
	MIXPANEL_SESSION_REPLAY: 'Mixpanel Session Replay',
	AFFIRM: {
		AFFIRM_PAYMENT_METHOD_TOGGLED: 'Affirm Payment Method Toggled',
		AFFIRM_PAYMENT_METHOD_CTA_CLICKED:
			'Affirm Payment Method More Details Clicked',
	},
};

export const SELECT_PAGE = {
	PAGE_VIEWED: 'Select Page Viewed',
	IMAGE_CLICKED: 'Select Page Image Clicked',
	IMAGE_VIEWED: 'Select Page Image Viewed',
	TAB_SWITCH: 'Select Page Tab Switched',
	INFO_DRAWER_OPEN: 'Important Information Drawer Opened',
	CONTENT_TAB_SECTION: 'Content Tab Section Clicked',
	EXPERIMENT_VIEWED: 'Experiment Viewed',
	RATING_WIDGET_CLICKED: 'Ratings Widget Clicked',
	SELECT_PAGE_CTA_CLICKED: 'Select Page CTA Clicked',
	DATE_FILTER_APPLIED: 'Date Filter Applied',
	DRAWER_TYPE: 'Drawer Type',
};

//section names
export const PERSONA_PAGE = {
	TOP_THINGS_TO_DO: 'Top Things To Do',
	TOP_EXPERIENCES: 'Top Experiences',
	REVIEWS: 'Reviews',
	BROWSE_BY_THEMES: 'Browse By Themes',
	NEARBY_CITIES: 'Nearby Cities',
};
export const HOME_PAGE = {
	RECENTLY_VIEWED: 'Recently Viewed',
	TOP_DESTINATIONS: 'Top Destinations',
	TOP_RECOMMENDATIONS: 'Top Recommendations',
	TOP_THINGS_TO_DO_WORLDWIDE: 'Top Things To Do Worldwide',
	REVIEWS: 'Reviews',
	BEST_PARTNERS: 'Best Partners',
	BROWSE_BY_THEMES: 'Browse By Themes',
	PROMO_BANNER: {
		MANDAI_WILDLIFE: 'Mandai Wildlife Reserve Promo Banner',
	},
};

export const CITY_PAGE = {
	RECENTLY_VIEWED: 'City Recently Viewed',
	TOP_EXPERIENCES: 'Top Experiences',
	TOP_10_TRENDING_THINGS_TO_DO: 'Top 10 Trending Things To Do',
	TOP_THINGS_TO_DO: 'Top Things To Do',
	MUST_DO_THINGS: 'Must Do Things',
	BROWSE_BY_THEMES: 'Browse By Themes',
	NEARBY_CITIES: 'Nearby Cities',
	PERSONA_AFFINITY: 'Persona Affinity',
	TOP_ATTRACTIONS: 'Top attractions',
	HANDPICKED_ITINERARIES: 'Handpicked itineraries',
	MOST_LOVED_ITINERARIES: 'Most loved itineraries',
	TOP_OFFERS_TODAY: 'Top offers today',
	CITY_LONG_VIDEO_SECTION: 'City long Video Section',
	COMBOS: 'Combos',
	DO_MORE_SAVE_MORE: 'Do more, save more',
	CITY_VERTICAL_PRODUCT_FEED: 'City Vertical Product Feed',
	DEAL_OR_NO_DEAL: 'Deal or no deal?',
};

export const PRODUCT_PAGE = {
	PP_HEADING_HIGHLIGHTS: 'Highlights',
	PP_HEADING_INCLUSIONS: 'Inclusions',
	PP_HEADING_EXCLUSIONS: 'Exclusions',
	PP_HEADING_SUMMARY: 'Summary',
	PP_HEADING_SIMILAR_PROD: 'Other experiences similar to {0}',
	PP_HEADING_MY_TICKETS: 'My Tickets',
	PP_HEADING_TICKET_VALIDITY: 'Ticket validity',
	PP_HEADING_CANCELLATION_POLICY: 'Cancellation policy',
	PP_HEADING_FAQ: 'Know before you go',
	PP_LABEL_VIEW_GMAP: 'View in Google Maps',
	PP_TRENDING: 'Trending',
	PP_HEADING_WHERE: 'Where?',
	PPRS_RATINGS_AND_REVIEWS: 'Ratings & Reviews',
	PP_HEADING_ADD_INFO: 'Additional information',
	PP_OPERATING_HOURS: 'Operating hours',
};

export const COLLECTION_PAGE = {
	ABOUT_POI: 'About Poi',
	WHY_VISIT: 'Why Visit',
	HIGHLIGHTS: 'Highlights',
	DID_YOU_KNOW: 'Did you know?',
	PLAN_YOUR_VISIT: 'Plan Your Visit',
	GETTING_THERE_TAB: 'Getting there',
	ENTRANCES_TAB: 'Entrances',
	TIMINGS_TAB: 'Timings',
	AMENITIES_TAB: 'Amenities',
	ACCESSIBILITY_TAB: 'Accessibility',
	RESCTRICTED_ITEMS_TAB: 'Restricted Items',
	DRESS_CODE_TAB: 'Dress code',
	GUEST_GUIDELINES_TAB: 'Guest guidelines',
	BUS_STOP: 'Bus stop',
	METRO_STATION: 'Metro station',
	TRAIN_STATION: 'Train station',
	PARKING: 'Parking',
	TRAM_STATION: 'Tram station',
	ADDRESS: 'Address',
	CURATED_EXPERIENCES: 'Curated Experiences',
	WHY_DAY_TRIPS_WITH_HO: 'Why Day Trips with Headout',
	OTHER_DESTINATIONS: 'Other Destinations',
	REVIEWS: 'Reviews',
	FLOATING_ACTION_BUTTON: 'Sticky Action Button',
};

export const TRIGGERED_BY = {
	USER: 'User',
	AUTOMATIC: 'Automatic',
};

export const CATEGORY_SUBCATEGORY_PAGE = {
	POPULAR_THINGS_TO_DO: 'Popular Things to do',
	TOP_EXPERIENCES: 'Top Experiences',
};

export const LAYOUT_STYLE = {
	FULL_WIDTH: 'Full Width',
	GRID: 'Grid',
};

export const PLATFORM_NAME = {
	DESKTOP: 'DWeb',
	MOBILE: 'MWeb',
};
