import { EN as EN_AQUA } from '@headout/aqua/src/constants/labels/english';

export const EN = {
	...EN_AQUA,
	OR: 'or',
	AT: 'at',

	CTP_CFP_TOP_EXP_IN_CITY: {
		title: 'Top experiences in {0}',
	},

	CITY_PAGE_TOP_EXP: 'Top experiences',
	CITY_PAGE_TOP_ATTR: 'Top attractions',
	TOP_10_TRENDING_THINGS_TO_DO: 'Top 10 trending things to do',
	ONE_EXP_TO_EXPLORE: '{0} experience to explore',
	TOTAL_EXPS_TO_EXPLORE: '{0} experiences to explore',
	TOTAL_WAYS_TO_FALL_IN_LOVE_WITH_CITY: '{0} ways to fall in love with {1}',
	ONE_WAY_TO_FALL_IN_LOVE_WITH_CITY: '{0} way to fall in love with {1}',
	CTP_CFP_RECENTLY_CHECKED: 'You recently checked out',

	CTP_CFP_HEADOUT_PICKS: {
		title: 'Headout’s top recommendations',
	},

	/* ************* Tourlist Page *************** */
	// mobile categoryPage.jsx, image - https://imgur.com/MKkLkKK.jpg
	CP_SORT_POPULARITY: 'Most popular',

	CP_PICKED_FOR_YOU: 'Picked for you',
	CP_PRICE_LOW_HIGH: 'Price (low to high)',
	CP_PRICE_HIGH_LOW: 'Price (high to low)',
	CPEX_VIEW_ALL: 'View all Experiences',

	// filters.jsx , image - https://imgur.com/YOtHkS2.jpg
	CPF_TITLE: 'Filters',

	CPF_SORT_BY: 'Sort by',
	CPF_PRICE_RANGE: 'Price Range',
	CPF_EVENT_TIME: 'Event Time',
	CPF_EVENT_DATE: 'Event Date',
	CPF_RESET: 'Reset Filters',

	// timeFilters.jsx, image - https://imgur.com/eMd94jf.jpg
	CPTF_MORNING: 'Morning',

	CPTF_NOON: 'Afternoon',
	CPTF_EVE: 'Evening',
	CPTF_NIGHT: 'Night',
	TIME_ONWARDS: '{0} onwards',
	FROM_TO_TIME: '{0} to {1}',

	// Mobile Sticky Footer, image - https://imgur.com/jgvZWiO.jpg
	STICKY_FOOTER: {
		HEADING: {
			CITIES: 'Cities',
			EXPLORE: 'Explore',
			SEARCH: 'Search',
			ACCOUNT: 'Account',
			CATEGORIES: 'Categories',
			GETHELP: 'Get Help 24/7',
			ACCEPTED_PAYMENTS: 'We Accept',
		},
	},

	CT_HEADER: 'Top Cities',
	BBC_SAVE_DISCOUNT: 'You save {0}%',
	BBC_CTA_SELECT_DATE: 'Select a date',
	BBC_CTA_PICK_DATE: 'Pick a date',
	BBC_BEST_PRICE: 'Best price guaranteed',

	BBC_CASHBACK_INFO:
		'You will receive {0} cashback when you book this experience. ' +
		'Your cashback will automatically be credited to your Headout account and can be used towards any future booking with Headout.',

	BBC_LOGIN_INFO:
		'Sign up to get {0} cashback as Headout Credits. Use them to save on your next experience. Valid forever.',

	LAST_ENTRY: 'last entry',
	CLOSED: 'Closed',
	OPERATING_HOURS: 'Operating hours',
	SELECT_SCHEDULE: 'Select Schedule',

	//productCard.jsx
	//Newly Arrived
	PRODUCT_CARD: {
		CASHBACK: '{0} Cashback',
		SAVE: 'Save {0}%',
		YOUR_PICK: 'Your pick',
	},

	// contentBookButton.jsx
	CBB_CHECK_AVAIL: 'Check availability',

	CBB_BOOK_NOW: 'Book now',

	// supportcard.jsx, PPSC , pp-> Product page
	PPSC_CONTACT_TITLE: 'Have any questions?',

	PPSC_CONTACT_DESC: 'Please feel free to contact our support 24x7',
	PPHP_PITCH_1: 'Trusted platform used by 100K+ people, each month',
	PPHP_PITCH_2: 'Get the lowest prices and last minute availability',
	PPHP_PITCH_3: 'Discover and connect with 10,000+ experiences',
	PPHP_PITCH_4: 'Browse verified reviews and professional photographs',
	RATINGS: { singular: 'rating', plural: 'ratings' },

	// productPageDescriptors.jsx, PPD
	PPD_MOBILE_TICKET: 'Mobile ticket',

	PPD_HOTEL_PICKUP: 'Hotel pickup',
	PPD_INSTANT_CONFIRMATION: 'Instant confirmation',
	PPD_SKIP_LINE: 'Skip the line',
	FLEXIBLE_DURATION: 'Flexible duration',
	PPD_FLEXIBLE_DURATION: 'Explore at your pace',

	PPD_FLEXIBLE_DURATION_SUBTEXT:
		'Choose your entry time, stay as long as you like',

	PPD_FREE_CANCEL: 'Free cancellation',
	PPD_AUDIO_GUIDE: 'Audio guide',

	PPD_AUDIO_GUIDE_SUBTEXT:
		'Enhance your experience with multilingual audio guide',

	PPD_GUIDED_TOUR: 'Guided tour',
	PPD_RETURN_TRANSFERS: 'Transfers included',
	PPD_MEALS_INCLUDED: 'Meals included',

	PPD_MEALS_INCLUDED_SUBTEXT:
		'Indulge in a sumptuous meal as a part of your experience',

	PPD_VALIDITY: 'Extended validity',
	PPD_GROUP_SIZE: 'Group size {0}',
	PPD_VIEW_TIMINGS: 'View timings',
	PPD_OPEN_TODAY: 'Open today',
	PPD_OPENS_TOMORROW: 'Opens tomorrow',
	PPD_OPENS_ON: 'Opens {0}',

	PPD_EXTENDED_VALIDITY:
		'Visit anytime until {0} without worrying about committing to a date today',

	GET_CASHBACK: 'Get {0} cashback',
	GET_CASHBACK_LONG: 'Get {0} Cashback on booking this experience',
	GET_CASHBACK_HEADER: 'Get {0} cashback as Headout credits',
	GET_CASHBACK_INFO: 'Use them to save more on your next booking',

	// productPage.jsx, PP
	PP_HEADING_HIGHLIGHTS: 'Highlights',

	PP_HEADING_INCLUSIONS: 'Inclusions',
	PP_HEADING_EXCLUSIONS: 'Exclusions',
	PP_HEADING_ADD_INFO: 'Additional Information',
	PP_HEADING_MY_TICKETS: 'My tickets',
	PP_HEADING_WHERE: 'Where?',
	PP_LABEL_VIEW_GMAP: 'View in Google Maps',
	PP_TRENDING: 'Trending',

	PP_MACHINE_TRANSLATED_CALLOUT_HEADING_TRANSLATED:
		'Some parts of this page have been auto-translated.',

	PP_MACHINE_TRANSLATED_CALLOUT_HEADING_ORIGINAL:
		'Content on this page is in its original language.',

	PP_MACHINE_TRANSLATED_CALLOUT_CTA_TEXT_ORIGINAL:
		'See the original content in English',

	PP_MACHINE_TRANSLATED_CALLOUT_CTA_TEXT_TRANSLATED:
		'See the translated content in {0}',

	PP_SCROLL_TO_TOP: 'Scroll to the top',

	// readMoreBox.jsx, PPRM
	PPRM_READ_MORE: '+ Read more',

	// reviewSection.jsx, image - https://imgur.com/bQPs06G.jpg
	PPRS_RATINGS_AND_REVIEWS: 'Ratings & Reviews',

	PPPR_SHOW_MORE_CTA: 'Show more reviews',

	PPRS_AGGREGATED_COUNTRIES:
		'Guests from <strong>{0}</strong> and <strong>over {1} countries</strong> have loved this experience',

	RATING_LEVEL: {
		5: 'Excellent',
		4: 'Very Good',
		3: 'Average',
		2: 'Poor',
		1: 'Terrible',
	},

	REVIEW_COUNT: '{0} reviews',
	SORT_BY: 'Sort by',
	MOST_RELEVANT: 'Most relevant',
	RATING_HIGH_TO_LOW: 'Rating: high to low',
	RATING_LOW_TO_HIGH: 'Rating: low to high',
	APPLY: 'Apply',
	RESET: 'Reset',
	SEE_MORE: 'See more',
	SEE_LESS: 'See less',
	VIEW_ALL_IMAGES: 'View all images',

	// productBottomTrustInfo.jsx PBTI
	PBTI_TITLE_1: '100% SECURE PAYMENTS',

	PBTI_TITLE_2: 'TRUSTPAY',
	PBTI_TITLE_3: 'HAVE A QUESTION?',
	PBTI_TITLE_4: 'BOOK ON THE GO',
	PBTI_DESC_1: 'All major credit & debit cards accepted',
	PBTI_DESC_2: '100% Payment Protection and Best Price Guarantee',
	PBTI_DESC_3: 'Live chat with our local experts 24/7 or browse our FAQs',
	PBTI_DESC_4: 'Download the app and get app-only offers at your fingertips',

	// missingAnyInfo.jsx
	YES: 'Yes',

	NO: 'No',
	PPMI_MISSING_ANY_INFO: 'Missing any information?',
	PPMO_LET_US_KNOW: "Please let us know what's missing on",
	PPMI_CHAT: 'Chat',
	PPMI_THANKS: 'Thank you for your feedback.',

	// image - https://imgur.com/AFMdJpF.jpg
	// selectScreen.jsx
	VP_TITLE_DATE: 'Event date',

	VP_TITLE_SELECT_DATE: 'Select a date',
	VP_TITLE_TIME: 'Your starting time',
	VP_TITLE_SHOWTIME: 'Select a Showtime',
	VP_TITLE_SELECT_TIME_SLOT: 'Select a time slot',
	VP_TITLE_SELECT_PREFERENCE: 'Select your preference',
	VP_DATE_CHANGED: 'Selected date has been changed to',

	BOOKING_PAGE_TIME: {
		HOUR: ' hr',
		HOURS: ' hrs',
		MINUTE: ' min',
		MINUTES: ' mins',
	},

	SOLD_OUT: 'Sold out',
	REMOVE: 'Remove',

	// dateButton.jsx
	VPDB_TODAY: 'Today',

	VPDB_TOM: 'Tomorrow',
	VPSM_YOUR_SEATS: 'Select a seat',

	VPSM_SEATS_SELECTED: {
		SINGLE: '{0} Seat Selected',
		PLURAL: '{0} Seats Selected',
	},

	VPSM_SEAT_REMOVED: 'Seat Removed',

	VPSM_ISI:
		'Our intelligent system will automatically pick the best seats available in the section you chose.',

	// horizontalDateList.jsx
	HORIZONTAL_DATE_LIST: {
		MORE_DATES: 'More dates',
		FOOTNOTE: 'All prices are in {0}',
		WHICH_DAY: 'Which day?',
	},

	// timeListItem.jsx
	VPTLI_ENTER_INFO: 'Enter anytime within operating hours',

	// priceInfo.jsx
	VPPI_DISCOUNT: '{0}% OFF',

	// tourItem.jsx
	VPVI_TICKETS_LEFT: 'Only {0} tickets left',

	VPVI_TICKET_LEFT: 'Only 1 ticket left',
	VPVI_SELLING_FAST: 'Hurry, selling out fast!',
	VPVI_NEXT_AVAIL: 'next available at',
	VPVI_FLEX_ENTRY: 'Flexible entry',

	// tourUtils.js
	VPVD_FIXED: 'Duration: {0}',

	VPVD_FIX_FLEXI: 'Duration: Flexible hours',
	VPVD_FLEXI_FLEXI: "Duration: As long as you'd like",
	STARTS_AT: 'Starts at: {0}',
	OPENS_AT: 'Opens at: {0}',
	VPVS_CLOSES_AT: 'Closes at: {0}',
	VPVS_OPERATING_HOURS: 'Operating hours:\n{0} - {1}',

	// FromPrice.jsx
	SAVE_PERCENT: 'Save {0}%',

	// bottomBar.jsx
	NEXT: 'Next',

	PREVIOUS: 'Previous',

	ITINERARY: {
		TAB: 'Itinerary',
		HEADING: 'Experience itinerary',
		VIEW_ITINERARY: 'View Itinerary',
		VIEW_DETAILS: 'View details',
		PASSES_BY_SECTION_HEADING: 'Passes By',
		SUB_SECTION_HEADING: {
			HIGHLIGHTS: 'Highlights',
			NEARBY_THINGS_TO_DO: 'Nearby things to do',
			THINGS_TO_DO: 'Things to do',
		},
		STOP_CARD: {
			TITLE: {
				START: 'START POINT',
				END: 'END POINT',
				STARTING_POINT: 'STARTING POINT',
				STOP: 'STOP',
				ENDING_POINT: 'ENDING POINT',
				PASSING_BY: 'PASSING BY',
			},
			MULTI_POINTS_AVAILABLE: {
				START: '{0} starting points available',
				END: '{0} ending points available',
			},
		},
		GET_DIRECTION: 'Get direction',
		DESCRIPTORS: {
			DURATION: {
				FULL: '{0} hours {1} mins',
				WITHOUT_HOURS: '{0} mins',
				WITHOUT_MINS: '{0} hours',
			},
			ATTRACTIONS_COUNT: '{0} attractions',
			ACTIVITIES_COUNT: '{0} activities',
			TOTAL_DURATION: 'TOTAL DURATION',
			FREQUENCY: 'FREQUENCY',
			FIRST_DEPARTURE: 'FIRST DEPARTURE',
			LAST_DEPARTURE: 'LAST DEPARTURE',
			FIRST_DEPARTURE_TIME: 'FIRST DEPARTURE TIME',
			LAST_DEPARTURE_TIME: 'LAST DEPARTURE TIME',
			MODE_OF_TRANSPORT: 'MODE OF TRANSFER',
			FOOD_AND_DRINKS: 'FOOD & DRINKS',
		},
		WALK_DURATION: '{0} walk',
		MORE: '{0} more',
		TIMELINE_VIEW: 'Timeline',
		MAP_VIEW: 'Map',
		MAP_OVERLAY: 'Use CTRL/⌘ + scroll to move the map',
		VIEW_EXPERIENCE_MAPPED_OUT: 'View your experience all mapped out.',
		OPEN_MAP_VIEW: 'Open map',
		START_POINT_SAME_AS_END_POINT:
			'Your ending point would be same as your start point',
		ROUTES: 'Routes',
		SIGHTS_COVERED: 'Sights covered',
		BOARDING_POINT: 'Boarding point',
		BOARDING_POINTS: 'Boarding points',
		LANDMARKS: 'Landmarks',
		VIEW_IN_MAPS: 'View in maps',
	},

	COOKIE_CONSENT: {
		BASIC_DESCRIPTION:
			'We use cookies and similar technologies for many purposes, including to improve your experience on our site and for ads.',

		MANAGE_PREFERENCES: 'Manage',
		COOKIES: 'Cookies:',
		ACCEPT: 'Okay',
		PREFERNCES_SAVED: 'Your preferences have been saved',
		ALLOW_ALL: 'Allow all',
		DENY_ALL: 'Deny all',
		HEADING: 'We value your privacy, always',

		DESCRIPTION: `*   Cookies are small files placed on Your computer, mobile device, or any other device by Website/Platform, containing the details of Your browsing history among its many uses.
*   We use different types of cookies to track Your usage of Our Platform and store certain information that further helps us to provide You with more personalized services. Certain cookies may get placed on Your Device because of Your usage of any services available on Our Platform.
*   Broadly, We use two different sets of technologies under this head:
    *   **Cookies or Browser Cookies.** it is a small file that automatically gets placed on Your Device when You access Our Platform. You can instruct Your browser to refuse all Cookies. In case, if You do not accept Our Cookies, You may not be able to use some parts of Our Platform.
    *   **Web Beacons.** Also referred to as clear gifs, pixel tags, and single-pixel gifs that are attached in various sections of Our Platform and Our emails that permit us to monitor and understand the activity of Users.
*   These Cookies stored on Your Device can be "Persistent" or "Session" Cookies. Persistent Cookies are the ones that remain on Your Device even when You go offline, whereas, Session Cookies are automatically deleted as soon as You close Your web browser.
*   We use both Session and Persistent Cookies for the purposes set out below:
    *   Necessary / Essential Cookies
        *   _Type:_ Session Cookies
        *   _Purpose:_ These Cookies are essential for You to access different sections of the Platform and if these are not allowed You may not be able to access the services available on the Platform. These cookies further help us to authenticate the Users and prevent any sort of fraudulent activity on the Platform.
    *   Cookies Policy / Notice Acceptance Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies identify if Users have accepted the use of Cookies.
    *   Functionality Cookies
        *   _Type:_ Persistent Cookies
        *   _Purpose:_ These Cookies allow us to remember choices You make when You use the Platform, such as remembering Your login details or language preference. The larger purpose of these Cookies is to provide You with a more personalized experience every time You use the Platform.

All capitalized terms used above are defined in the Privacy Policy. For a detailed understanding please refer [Privacy Policy](/privacy-policy/)`,
	},

	/* ************** Checkout Page ******************* */
	CP_PERSON_TYPES: {
		ADULT: { singular: 'Adult', plural: 'Adults' },
		CHILD: { singular: 'Child', plural: 'Children' },
		YOUTH: { singular: 'Youth', plural: 'Youths' },
		INFANT: { singular: 'Infant', plural: 'Infants' },
		SENIOR: { singular: 'Senior', plural: 'Seniors' },
		STUDENT: { singular: 'Student', plural: 'Students' },
		MILITARY: { singular: 'Military', plural: 'Military' },
		MALE: { singular: 'Male', plural: 'Males' },
		FEMALE: { singular: 'Female', plural: 'Females' },
		COUPLE: { singular: 'Couple', plural: 'Couples' },
		GENERAL: { singular: 'Guest', plural: 'Guests' },
		EUR_STUDENT: { singular: 'EU-Student', plural: 'EU-Students' },
		NON_EUR_STUDENT: {
			singular: 'Non-EU Student',
			plural: 'Non-EU Students',
		},
		EUR_CHILD: { singular: 'EU-Child', plural: 'EU-Children' },
		EUR_ADULT: { singular: 'EU-Adult', plural: 'EU-Adults' },
		NON_EUR_CHILD: {
			singular: 'Non-EU Child',
			plural: 'Non-EU Children',
		},
		NON_EUR_ADULT: {
			singular: 'Non-EU Adult',
			plural: 'Non-EU Adults',
		},
		FAMILY: { singular: 'Family', plural: 'Families' },
		RESIDENT_IN_CATALONIA: {
			singular: 'Resident in Catalonia',
			plural: 'Residents in Catalonia',
		},
		ADULT_REFUNDABLE: {
			singular: 'Adult Refundable',
			plural: 'Adults Refundable',
		},
		CHILD_REFUNDABLE: {
			singular: 'Child Refundable',
			plural: 'Children Refundable',
		},
		GENERAL_REFUNDABLE: {
			singular: 'General Refundable',
			plural: 'Persons Refundable',
		},
		SENIOR_REFUNDABLE: {
			singular: 'Senior Refundable',
			plural: 'Seniors Refundable',
		},
		RESIDENT: {
			singular: 'Resident',
			plural: 'Residents',
		},
		TOURIST: {
			singular: 'Tourist',
			plural: 'Tourists',
		},
		DISCOUNTED_TICKETS: {
			singular: 'Discounted Tickets',
			plural: 'Discounted Tickets',
		},
		GROUP: {
			singular: 'Group',
			plural: 'Groups',
		},
		PRIVATE_GROUP: {
			singular: 'Private Group',
			plural: 'Private Groups',
		},
		SMALL_GROUP: {
			singular: 'Small Group',
			plural: 'Small Groups',
		},
		UNEMPLOYED: {
			singular: 'Unemployed',
			plural: 'Unemployed',
		},
		REDUCED_PRICE: {
			singular: 'Reduced Price',
			plural: 'Reduced Price',
		},
		DISABLED_PERSON: {
			singular: 'Person with disability',
			plural: 'Persons with disability',
		},
		// Refer this: https://headout.slack.com/archives/C07CU9U5Q/p1611640652005500 for reasoning for PAX down below
		FAMILY_2_ADULT_1_CHILD: {
			singular: 'Family (2 adults + 1 child)',
			plural: 'Family (2 adults + 1 child)',
		},
		FAMILY_2_ADULT_2_CHILDREN: {
			singular: 'Family (2 adults + 2 children)',
			plural: 'Family (2 adults + 2 children)',
		},
		FAMILY_2_ADULT_3_CHILDREN: {
			singular: 'Family (2 adults + 3 children)',
			plural: 'Family (2 adults + 3 children)',
		},
		FAMILY_2_ADULT_4_CHILDREN: {
			singular: 'Family (2 adults + 4 children)',
			plural: 'Family (2 adults + 4 children)',
		},
		SWISS_TRAVEL_PASS: {
			singular: 'Swiss Travel Pass',
			plural: 'Swiss Travel Pass',
		},
		SWISS_HALF_FARE_CARD: {
			singular: 'Swiss Half Fare card or SBB G.A.',
			plural: 'Swiss Half Fare card or SBB G.A.',
		},
		PRIMARY_STUDENT: {
			singular: 'Primary Student',
			plural: 'Primary Students',
		},
		SECONDARY_STUDENT: {
			singular: 'Secondary Student',
			plural: 'Secondary Students',
		},
		EURAIL_PASS_HOLDER: {
			singular: 'Eurail Pass Holder',
			plural: 'Eurail Pass Holders',
		},
		MALAYSIAN_ADULT: {
			singular: 'Malaysian Adult',
			plural: 'Malaysian Adults',
		},
		NON_MALAYSIAN_ADULT: {
			singular: 'Non-Malaysian Adult',
			plural: 'Non-Malaysian Adults',
		},
		MALAYSIAN_CHILD: {
			singular: 'Malaysian Child',
			plural: 'Malaysian Children',
		},
		NON_MALAYSIAN_CHILD: {
			singular: 'Non-Malaysian Child',
			plural: 'Non-Malaysian Children',
		},
	},

	CP_ABOVE_X_YEAR: 'Above {0} yrs',
	CP_X_TO_Y_YEAR: '{0} to {1} yrs',
	CP_UNDER_X_YEAR: 'Under {0} yrs',

	CP_BREAKUP_SECTION_TYPES: {
		CASHBACK: {
			GUEST: 'Sign up to get {0} cashback!',
			LOGGED_IN: "You'll get {0} as cashback!",
		},

		CASHBACK_UNDERLINED: {
			LOGGED_IN: "You'll get <u>{0} as cashback!</u>",
		},

		CASHBACK_BLOCK_SUBHEADING:
			'Use your cashback to book experiences across 80+ cities worldwide!',

		DISCOUNT: 'Discount',
		FEES_FOR: 'Fees for {0}',
	},

	CP_SELECT_NUMBER_OF_TIX: 'Select number of tickets',
	MIN_PAX_MESSAGE: 'Please select a minimum of {0} tickets',
	CP_ANY_AGE: 'Any age',

	CP_PAX_RATIO_VALIDATION_TEXT:
		'You need to have a minimum of {0} guests here.',

	CP_VALIDATION_TEXT: {
		SINGULAR: 'Please select at least one ticket to continue',
		PLURAL: 'Please select at least {0} tickets to continue',
	},

	CP_YOU_PAY: "You'll pay {0}",

	// bookingSummaryCard.jsx
	CP_RECEIVE_CASHBACK: "You'll receive {0} cashback",

	CP_BILLABLE: {
		CURRENCY: 'Your card will be charged in {0}',
		DESCRIPTION:
			'You are paying in {0} and your total is {1}. The total you pay could differ slightly according to your bank’s exchange rates.',
	},

	CP_TOTAL_PAYABLE: 'Total payable',
	CPTI_SUPPORT: '24/7 Support',

	// checkoutDetailsComponent.jx
	CPCD_PROMO_CODE_APPLIED: 'Promo Code Applied',

	// press.jsx , image- https://imgur.com/K0tyAht.jpg
	CPPT_ENTRIES: [
		{
			title: 'Apple',
			description: '"Featured as one of the Best Travel Apps in 2016"',
		},
		{
			title: 'CNN Money',
			description: '"The Go-to app for last minute activities."',
		},
		{
			title: 'TechCrunch',
			description:
				'"The Travel App That Puts A Concierge In Your Pocket."',
		},
	],

	// promoCode.jsx
	CPPC_HAVE_COUPON: 'Have a promo code?',

	CPPC_ENTER_PROMO: 'Enter promo code',
	CPPC_COPY_CODE: 'COPY CODE',
	COPY: 'Copy',
	COPIED: 'Copied',

	CPPC_USE_CODE: {
		withoutTitle: 'Use code <strong>{0}</strong> upon checkout',
	},

	CPPC_USE_CODE_CHECKOUT: {
		WITHOUT_TITLE: 'Use code <strong>{0}</strong> at checkout',
	},

	CPPC_USE_CODE_DISCOUNT: {
		WITHOUT_TITLE: 'Use code <strong>{0}</strong> for discount',
	},

	CPPC_APPLY: 'APPLY',
	CPPC_APPLIED: 'Applied',
	CPPC_HURRY_LIMITED_TIME: 'Hurry, limited time offer only.',

	CPPC_DISCOUNT_CASHBACK_APPLIED:
		'Applied {0}. You saved {1} and will receive {2} additional cashback',

	CPPC_DISCOUNT_APPLIED: 'Applied {0}. Woohoo, you saved {1}! 🥳',

	CPPC_CASHBACK_APPLIED:
		'Applied {0}. You will receive {1} additional cashback',

	CPUD_ENTER_DETAILS: 'Guest details',
	LEAD_GUEST_DETAILS: 'Lead guest details',
	CPUD_YOUR_TICKETS_SENT_HERE: 'Your order details will be sent here',
	CPUD_MENTION_COUNTRY: 'Please mention the country code',
	CPUD_ERROR_NAME: 'Please enter a valid full name',
	CPUD_ERROR_EMAIL: 'Add a valid email ID',
	CPUD_ERROR_EMAIL_INVALID_CHARACTERS: 'The email contains invalid character',
	CPUD_DROPDOWN_HELP: 'Search or pick your option',
	CPUD_NAME_HELPER_TEXT: 'Must match ID',

	USER_FIELDS_UNQIUE_NAME:
		'Guest names cannot be the same for more than one guest.',

	PMPCMN_ADD_PMPCMN_FULL_NAME: 'Please enter both the first and last names',
	CPUD_ERROR_WEIGHT: 'Please enter a valid weight',
	CPUD_ERROR_MAX_PAX: 'Maximum {0} people are allowed for this tour',
	CARD_HOLDER_NAME_ERROR: 'Add name as printed on the card',
	CPUD_ENTER_VALID_CARD: 'Enter a valid card number',
	CPUD_MIN_CVV_ERROR: 'Minimum 3 characters required',
	CPUD_EXPIRED_CARD_ERROR: 'Invalid expiry date',
	CPUD_ERROR_VALUE: 'Please ensure all the fields are filled correctly',
	ERROR_IS_REQUIRED: 'This field is required',

	SIGN_IN_TO_APPLY: {
		message: 'You need to {0} to apply this coupon.',
		sign_in: 'sign in',
	},

	// CARD VALIDATION
	CPUD_CARD_ERROR_NAME: 'Please enter a valid card holder name',

	CPUD_CARD_ERROR_NUMBER: 'Please enter a valid card number',
	CPUD_CARD_ERROR_MONTH: 'Invalid month',
	CPUD_CARD_ERROR_YEAR: 'Invalid year',
	CPUD_CARD_ERROR_CVV: 'Invalid CVV',

	PHONE_INPUT_FIELD: {
		displayname: 'Phone number',
		COUNTRY_CODE_PLACEHOLDER: 'Type country name or country code',
		SEARCH_COUNTRY_CODE: 'Search country code',
	},

	//checkoutScreen.jsx
	CPPM_CONTEXT_ERROR_BOARD: {
		title: "Damn, this slot/price isn't available anymore",
		description:
			'Looks like this experience is in high demand. Can you try booking this again?',
		ctxtext: 'BOOK AGAIN',
	},

	/* ***************** Checkout Page ************** */
	PMPCMN_RATING: '{0} star rating by {1} customers',

	GUESTS: 'Guests',
	PMPCMN_NO_TICKETS_ERROR: 'Please select at least one ticket to continue.',
	PMPCMN_NO_TICKETS_MESSAGE: 'No ticket selected',
	PMPCMN_CONFIRM_EMAIL: 'Confirm email address',
	PMPCMN_VERIFY_EMAIL_ERROR: `Oops! the email IDs don't match`,
	PMPCMN_PHONE_NO_ERROR: 'Please enter a valid mobile number',
	PMPCMN_NUMBER_ERROR: 'Add a valid numerical value',
	PMPCMN_FULL_NAME: 'Full name',

	PMPCMN_CARD_SECURE_TEXT:
		'Your card details are secured using 2048-bit SSL encryption.',

	CPUD_EMAIL_HELPER_TEXT: "We'll send your tickets here",
	CPUD_CONFIRM_EMAIL_HELPER_TEXT: "Just to ensure we've got this right",

	PHONE_HELPER_TEXT: {
		DEFAULT: 'We may reach out for updates here',
		WHATSAPP: 'We may reach out for booking updates here over SMS/WhatsApp',
	},

	CPUD_ERROR: 'Error : ',
	CPUD_ERROR_PHONE: 'Please enter a valid phone number',

	CP_CONFIRM_NAVIGATION:
		'Your booking is in progress and will get affected if you move away from this page.',

	TERMS_AND_CONDITION:
		'By continuing, you agree to the {0}, {1} and the {2}.',

	PMPCMN_TERMS_2: 'general terms',
	PMPCMN_TERMS_4: 'privacy policy',

	// paymentCardComponent.jsx
	PMP_CARD_NUMBER: {
		title: 'Card number',
	},

	PMP_CARD_EXPIRY: { title: 'Expiry', hint: 'MM/YY' },

	PMP_CARD_CVV: {
		title: 'CVV',
	},

	PMP_HOLDER_NAME: {
		title: 'Name on card',
	},

	/* ********** Confirmation Page ******************* */
	CONFIRMATION_PAGE_TITLE: 'Headout: Confirmation Page',

	// addTocalender.jsx
	CNF_ADD_TO_CALENDER: 'Add To Calendar',

	CNF_CONTINUE: {
		TITLE: {
			GOOGLE: 'Continue with Google',
			APPLE: 'Continue with Apple',
			EMAIL: 'Continue with email',
		},
		DESCRIPTION:
			'Get access to order-status & many more additional cashbacks',
	},

	UPCOMING_MONTHS_STRIP: 'Dates not available in {0}',
	UPCOMING_MONTHS_STRIP_SINGLE_MONTH: 'Dates not available in {0}',
	LIMITED_DATES_AVAILABLE: 'Limited dates available',

	// seatmapConfirmation.jsx
	CNFSM_YOU_GOT_CASHBACK: 'Yay! You got {0} cashback.',

	// cashbackMissed.jsx
	JUST_MISSED_CASHBACK: 'You just missed a cashback of {0}!',

	CLAIM_CASHBACK: 'Claim Cashback',
	CASHBACK_SIGN_IN: 'Get it back by signing in with just a tap.',

	// cashbackEligible.jsx
	CNF_CASHBACK_CONGRATS: 'Congratulations {0}!',

	CNF_CASHBACK_ELIGIBLE: 'You are eligible for {0} cashback',

	/* ********** Generic ******************* */
	// whyHeadoutStrip.jsx
	GMHS_WHY_HEADOUT: 'Why Headout?',

	GWHS_TITLE_DESKTOP:
		"We've served {0} million+ guests and we are here for you",

	GWHS_ITEMS_DESKTOP: [
		{
			title: '{0} million+',
			description: 'Happy customers across 10,000+ experiences',
		},
		{
			title: '',
			description: '28,000+ verified reviews with 90% excellent score',
		},
		{
			title: 'In the media',
			description: 'Featured and recommended by the best brands',
		},
		{
			title: '24 x 7 help center',
			description:
				'Have a question? Live chat with local experts anywhere, anytime',
		},
	],

	GWHS_ITEMS_MOBILE: [
		{
			title: '{0} million+',
			description: 'Happy customers across 10,000+ experiences',
		},
		{
			title: '',
			description: '28,000+ verified reviews with 90% excellent score',
		},
		{
			title: 'In the media',
			description: 'Featured and recommended by the best brands',
		},
	],

	//* ---------Desktop--------- *//
	/* ********** Category Page ******************* */
	// ProductNotFound.jsx
	DPCP_PRODUCT_NOT_FOUND: {
		withFilter: 'No products with these filters.',
		withoutFilter: 'No products here.',
	},

	DPCP_SIDE_BAR_ALL_EXP: 'All Experiences',
	DPCP_SIDE_BAR_BEST_SELLING: 'Best Selling Experiences',
	DPCP_SIDE_BAR_TRENDING_NOW: 'Trending Now',

	/* ********** Profile Page ******************* */
	PROFILE_PAGE: {
		MARKUP_SECTION: {
			TITLE: 'Markup Type',
			UPDATE_DESC: 'Update Markup Value/Type',
			PERCENTAGE: 'Markup Percentage',
			AMOUNT: 'Markup Amount',
			TYPES: {
				ABSOLUT: 'Absolute',
				PERCENTAGE: 'Percentage',
			},
		},

		CREDITS_SECTION: {
			EMPTY_CREDITS: {
				TITLE: 'Headout Credits',
				MESSAGE: 'Book your next experience to start earning credits.',
			},
			INFO: {
				TITLE: 'How do Headout Credits work?',
				DESC:
					'You can use these credits to buy any experience on Headout anywhere in the world and save money. ' +
					"They'll be automatically applied on check out. So what are you waiting for?",
				MESSAGE:
					'Your credits are valid for 1 year from the issued date.',
			},
			EXPLORE: 'Explore Top Cities',
			BROWSE: 'Browse Headout Picks',
		},

		CITY: 'City',
	},

	DBPOC_OPEN_UNTIL: 'Open until {0}',

	// timesHeader.jsx
	VPTP_CHOOSE_ONE_OF_THE_BELOW: 'Choose one of the below options',

	CPCB_CONFIRM_N_PAY: 'Confirm & pay',

	// conceirgeOnRequest.jsx
	CPCOR_LIMITED_AVAILABILITY: {
		header: 'Tickets for this date are nearly sold out',
		content: {
			line1: 'Due to high demand for this event please contact our dedicated contact center using the options below',
			line2: 'Our friendly team will be able to confirm what tickets are still available and book this for you',
			line3: 'There is no wait time if you call',
		},
	},

	// customFields.jsx
	CPCF_EMAIL: {
		displayname: 'Email address',
	},

	RP_SHOW_ALL_REVIEWS: 'Show all reviews',
	HD_BEST_SELLERS: 'Bestsellers',
	FT_PRIVACY_POLICY: 'Privacy policy',
	FT_TERMS_USE: 'Terms of Usage',
	FT_MADE_WITH_LOVE_IN_HEADOUT: 'Made with ❤️\u00A0all over the 🌎',

	/* Section-1 */
	FT_HELP_CENTER: 'Help center',

	FT_CHAT: 'Chat with us',
	FT_CALL: 'Call +13478970100',
	FT_SUPPORT_MAIL: 'support@headout.com',
	FT_OUR_STORY: 'Our story',
	FT_CAREERS: 'Careers',
	FT_NEWSROOM: 'Newsroom',
	FT_COMPANY_BLOG: 'Company blog',
	FT_TRAVEL_BLOG: 'Travel blog',
	FT_EXP_PROVIDERS: 'Experience providers',
	FT_AFFILIATES_PORTAL: 'Affiliates',
	CREATORS_AND_INFLUENCERS: 'Creators & influencers',

	//* ---------Common (Header/footer/extras)--------- *//
	CMN_GO_HOME: 'Go Home',

	CMN_HEADOUT: 'Headout',
	CMN_ABOUT_US: 'About us',
	CMN_SIGN_IN: 'Sign in',
	CMN_DOWNLOAD_APP: 'Download app',
	CMN_BACK: 'BACK',
	CMN_AWESOME: 'Awesome!',
	CMN_NOTIFY_YOU: 'We will notify you.',
	CMN_SEE_ALL: 'See all',
	CMN_EDIT: 'EDIT',
	CMN_PROMO_CODE: 'Promo code',
	CMN_HEADOUT_DISCOUNT: 'Headout discount',
	CMN_HEADOUT_WALLET: 'Headout wallet',
	CMN_LIVE_CHAT: 'Have a question? Live chat with our experts 24/7',
	CMN_NEW: 'New',
	CMN_UNAVAILABLE: 'Unavailable',
	FROM: 'from',
	CMN_SAVE_UPTO_PERCENT: 'Save up to {0}%',
	CMN_NOTE: 'NOTE:',
	CMN_NEXT: 'NEXT',
	CMN_SELECT: 'SELECT',
	CMN_SOLD_OUT: 'SOLD OUT',

	CMN_DETAILS: {
		view: 'View details',
	},

	CMN_PARTNERS: 'Partners',
	CMN_DOWNLOAD_HEADOUT: 'Download the Headout app',

	CMN_CONTACT: {
		help: 'Help',
		chat: 'Chat',
		liveChat: 'Live Chat',
		call: 'Call {0}',
		email: 'Email',
		emailUs: 'Email us',
		facebookMessenger: 'Facebook Messenger',
		whatsapp: 'WhatsApp',
	},

	HELP: 'Help',

	PRODUCT_META_DESCRIPTION:
		'Book your {0} {1} tickets now! Best prices and deals for {0}. Find more information, timings, reviews and photos here.',

	COLLECTIONS_SITEMAP_META_TITLE_BASE: 'Top Things to do on Headout',

	COLLECTIONS_SITEMAP_META_TITLE_FILTER_PAGE:
		'Top Things to do on Headout | {0} | p{1}',

	COLLECTIONS_SITEMAP_META_DESCRIPTION:
		'Discover and book the best things to do around the world on Headout. From iconic attractions to offbeat experiences, from bucket list items to the latest shows in town.',

	CITIES_SITEMAP_META_TITLE_WORLD_BASE:
		'Top Cities to Visit in the World - Headout',

	CITIES_SITEMAP_META_TITLE_FILTER_PAGE:
		'Top Cities to Visit in {0} - Headout - p{1}',

	CITIES_SITEMAP_META_DESCRIPTION:
		'Check out our list of awesome cities to visit - from iconic landmarks to unforgettable experiences, these destinations are perfect for your next adventure.',

	TICKETS: 'Tickets',
	SEAT_SELECTION: 'Select seats',
	TOURS: 'Tours',
	CATEGORIES: 'Categories',
	DEALS: 'Deals',

	REVIEWS: {
		plural: 'Reviews',
	},

	INVENTORY_UNAVAILABLE: {
		HEADING: 'Uh oh, we’ve a problem',
		MESSAGE: `Sorry! All experiences for this date are either sold out or
		unavailable. But we’ve loads of them available on other dates. All
		you gotta do is be flexible.`,
		CTA: 'View all dates',
	},

	INVENTORY_ALERT: {
		ALERT_HEADER: 'This experience is unavailable',
		ALERT_CONTENT:
			"You'll be the first to know when these tickets are back online.",
		BUTTON_TEXT: 'Notify me',
		INPUT_PLACEHOLDER: 'Your email',
	},

	LANGUAGE: 'Languages',
	CURRENCY: 'Currency',
	POPULAR_CURRENCIES: 'Popular currencies',
	MORE_CURRENCIES: 'More currencies',
	SELECT_LANGUAGE: 'Select Language',
	SELECT_CURRENCY: 'Select Currency',

	// Search Bar
	SEARCH_BAR: {
		SELECT: 'Select City',
	},

	// User Profile Page
	UPP_TABS: {
		RESERVATIONS: 'My Bookings',
		CREDITS: 'Credits',
		SIGN_OUT: 'Sign out',
		SETTINGS: 'Settings',
		LEGAL: 'Legal',
		FAQ_SHORT: 'FAQs',
		MY_ACCOUNT: 'My Account',
		PRICE_MARKUP: 'Price Markup',
	},

	// CVR Boosters
	CVRB: {
		SELL_OUT_SOON: 'Likely to sell out',
		NO_ADDITIONAL_FEE: 'No additional fees',
	},

	RESET_PASSWORD: {
		OLD_PASSWORD: 'OLD PASSWORD',
		NEW_PASSWORD: 'NEW PASSWORD',
		CONFIRM_NEW_PASSWORD: 'CONFIRM NEW PASSWORD',
		ERROR_TEXT: "DOESN'T MATCH",
	},

	// Free Tours
	FREE_TOUR_CONTENT: {
		POPUP_COPY: 'Copy Code',
		MOBILE_BANNER_TITLE: 'Get Your Free Tour',
	},

	// World Wide Search
	SEARCH_LIST_PAGE: {
		WORLD_COLLECTION: 'World Wide Collections',
		WORLD_EXPERIENCES: 'World Wide Experiences',
		SHOWING_RESULTS: 'Showing results for',
		NO_RESULT_FOUND: 'Hmm, no results found.',
		NO_RESULT_SUBTEXT: 'We couldn’t find anything. Try something else?',
		GO_HOME: 'Go Home',
	},

	// Browser not supported popup
	NOT_SUPPORTED_POPUP: {
		TITLE: 'Uh oh, this browser is not supported.',
		DESC:
			"Unfortunately, we don't support this browser.\n" +
			'To get the best experience, please download the latest version of Google Chrome, Safari, Firefox or Microsoft Edge.',
	},

	//bookingSummaryCard.jsx, bookingSummarySeatmapCard.jsx
	YOU_SAVED: 'You saved',

	//variantCardList.jsx
	SELECT_DATE_AND_TIME: 'Select date and time',

	//singleDate.jsx
	NOT_AVAILABLE: 'Not available',

	//svgTourScreen.jsx
	SCREEN_TITLE: {
		DATE: 'DATE',
		SHOWTIME: 'SHOWTIME',
		PREFERENCE: 'PREFERENCE',
		START_TIME: 'START TIME',
	},

	//bookingCardBooking.jsx
	CARD_BOOKING_TITLE: {
		BOOKING_DETAILS: 'Booking Details',
		DATE: 'Date',
		TIME: 'Time',
		SEATS: 'Seats',
	},

	SELECT_SCREEN_PROMOCODE_NOTE: 'Promo codes can be applied at checkout',

	// Selector Modal
	SELECTOR_MODAL: {
		CURRENCY_CHANGE: 'Currency changed to {0}',
		LANGUAGE_CHANGE: 'Language changed to {0}',
	},

	// Checkout Page
	CHECKOUT_PAGE: {
		SUBSCRIPTION_TEXT:
			'You will get exclusive access to insider only deals, personalised offers and updates. You can unsubscribe anytime.',
		UNSUB_CHECKBOX_TEXT: {
			DEFAULT: "I don't want to receive updates and offers",
			WHATSAPP: 'Do not send me offers over email or updates on WhatsApp',
		},
		SELECT_PAYMENT_OPTION: 'Select your payment option',
		BOOK_WITH: 'Book with ',
		PAY_WITH_CARD: 'Pay with card',
	},

	// Don't change the keyNames as API provides that
	SEATMAP_SEATS_SELECTION: {
		UNAVAILABLE: 'This seat is no longer available.',
		SIDE_SEAT_LEFT_NOT_SELECTED:
			'Your current selection leaves an empty single seat next to it. Please choose another one.',
		SIDE_SEAT_RIGHT_NOT_SELECTED:
			'Your current selection leaves an empty single seat next to it. Please choose another one.',
		ALL_SEATS_AT_TABLE_NOT_SELECTED:
			'Please note that you need to purchase all seats at the table.',
		NON_CONTINUOUS_SELECTED:
			'Please select adjacent seats to proceed with the booking.',
		INVALID_QUANTITY_SELECTED:
			'Seats chosen are not available for booking at the moment. Please reselect the seats.',
		ERROR_MESSAGE:
			'This selection is not available. Please pick another one.',
	},

	PRICE_MARKUP: {
		BUTTON_STATE: {
			APPLYING: 'Applying',
			APPLY: 'Apply',
		},
		SUCCESS_MESSAGE: 'Price Markup successfully applied',
		ERROR_MESSAGE: 'Price Markup cannot be lower than 0',
	},

	EXTRA_CHARGES: {
		SERVICE_FEE: {
			TEXT: 'Service Fee',

			TOOLTIP_TEXT:
				'This small fee supports online payment processing charges, 24x7 concierge service, and helps us run a trusted global marketplace.',
		},
	},

	// COVID-19 SANITARY ALERT
	SANITARY_ALERT: {
		TITLE: 'Your safety is our priority',
		DESCRIPTION:
			'We have taken significant steps to improve cleanliness using disinfectants with comprehensive and frequent surface cleaning. Crowds are also being controlled to prevent congestion.',
		READ_MORE: 'Read More',
	},

	FREE_CANCELLATION_BANNER: {
		CANCELLATION_BEFORE_X_HOURS:
			'Free cancellation up to {0} hours before the start of your experience',
		CANCELLATION_BEFORE_X_DAYS:
			'Free cancellation up to {0} days before the start of your experience',
		CANCELLATION_ANYTIME:
			'Free cancellation anytime before the start of your experience',
	},

	LOGIN_MODAL: {
		TITLE: 'Welcome to Headout',
		SUBTITLE:
			'Sign in or register to get exclusive members-only deals, personalised recommendations and so much more',
		CONTINUE_BROWSING: 'Continue browsing',
	},

	EMAIL_LOGIN: {
		PLACEHOLDER: 'Email address',
		INPUT_ERROR: 'Please enter a valid email address',
		INVALID: 'Invalid email address',
		FIELD_DESC:
			'We’ll send a link to your email that will instantly sign you in',
		SEND_LINK: 'Send link',
		CHECK: 'Check your email!',
		EMAIL_SENT: 'Email Sent',
		INSTRUCTIONS: 'To sign in, tap the button in the email we sent to {0}',
	},

	ATTRACTION_TRACKER_PAGE: {
		CONTENT: {
			TOP: 'This is a list of all the important attractions and cultural hot-spots of the world and their current status. Theme parks, museums and attractions in countries such as Japan, Australia, Italy and more have started to open up with new ticketing and safety guidelines.',
			MIDDLE: 'While some landmarks are still closed, we can’t wait to see their status turn green to open. And when it does, you can bet Headout will be there to help you experience the best of it.',
			END: 'Until then, stay safe and be healthy.',
		},

		OPEN_IN_NEW_TAB: 'Open in a new tab',
		LIVE: 'Live Status Updates',
	},

	CITY_SEARCH_PAGE: {
		HEADING: 'Search all experiences and destinations',
		HEADING_MOBILE: 'Search experiences',
		CLOSE_CITIES: 'Top Destinations Near You',
		RECENT_SEARCHES: 'Recently searched',
	},

	HOME_PAGE: {
		CITIES_HEADING: "Explore world's top destinations",
	},

	HEADER: {
		MORE_ELEMENTS_SECTION: {
			AFFILIATE_LOGIN: 'Affiliate Login',
			BLOG: 'Blog',
			PARTNER: 'Partner',
			ABOUT_US: 'About us',
			DOWNLOAD_APP: 'Download App',
		},
	},

	SELECT_OPTION: 'Select an option',
	PLEASE_SELECT: 'Please make a selection',
	TOP_COLLECTIONS: 'Top Collections',
	COLLECTIONS_IN: 'Collections in {0}',
	EXPERIENCES_IN: '{0} Experiences in {1}',

	DOWNLOAD_APPS: {
		TITLE: 'World at your tap.',
		SUB_HEADING:
			'Get the Headout app to get tickets on your phone and access app-only deals. Talk about win-win.',
	},

	ALL_CATEGORIES: 'All Categories',

	COLLECTIONS_PAGE: {
		TOP_EXPERIENCES: 'Top experiences',

		COMPARISON_TABLE: {
			SELECT_DATE: 'Select a Date',
			DURATION: 'Duration',
			FLEXIBLE_DURATION: 'Flexible',
			INCLUSIONS: 'Inclusions',
			MORE_DETAILS: 'More details',
			MORE_INCLUSIONS: 'More Inclusions',
			LESS_INCLUSIONS: 'Less Inclusions',
			NEXT_AVAILABLE: 'Next Available',
		},
	},

	TOP_ATTRACTION: {
		GLOBAL: 'Top things to do worldwide',
		CITY: 'Top things to do in {0}',
		NO_CITY: 'Top things to do',
	},

	PLACES_TO_VISIT: {
		TITLE: 'Places to visit in {0}',
	},

	THINGS_TO_DO: {
		CITY: 'Browse by themes',
	},

	CATEGORY_PAGE: {
		RESET: 'RESET',
		ALL: 'All',
		APPLY: 'Apply',
		CATEGORIES_CITY: 'Categories in {0}',
		CATEGORIES_GLOBAL: 'Categories Worldwide',
		WORLDWIDE: 'Worldwide',
		NUMBERS_OF_EXPERIENCES: '{0} experiences',
		NUMBERS_OF_EXPERIENCE: '{0} experience',
		NO_RESULTS_FOUND: 'No products available, expand your search range',
		ALL_CATEGORIES: 'All Experiences',
		MIN_PRICE: 'Min Price',
		MAX_PRICE: 'Max Price',
	},

	SHOW_MORE: 'Show more',
	COLLAPSE_ALL: 'Collapse All',
	BREADCRUMB_HOME: 'Home',

	PAYMENT_METHOD: {
		CARD: 'Credit or debit card',
	},

	WISHLIST_BTN_SAVED: 'saved',

	// Loading spinner
	LOADING: 'Loading...',

	//collection page/footer
	PLUS_MORE: '+{0} more',

	MORE: 'more',
	ALL_DATES: 'All dates',
	TODAY: 'Today',
	TOMORROW: 'Tomorrow',
	SELECT_PREFERRED_DATE: 'Select your preferred date',
	SELECT_PREFERRED_TIME: 'Select your preferred time',
	SELECT_PREFERRED_LANGUAGE: 'Select your language',
	CHOOSE_LANGUAGE: 'Choose language',
	CHOOSE_OPTION: 'Choose an option',
	SINGLE_TIME: 'Time',
	SELECT_TIME: 'Select time',
	SELECT_TICKET_TYPE: 'Select your ticket type',
	PROCEED_TO_PAYMENT: 'Proceed To Payment',
	HERE: 'Here',
	ITINERARY_ID: 'Itinerary ID',
	AVAILABLE_ONLY_ON_DATE: 'This experience is available only on this date',
	AVAILABLE_ONLY_ON_TIME: 'This experience is available only at this time',
	AVAILABLE_ONLY_IN_LANGUAGE: 'This guided tour is available only in',

	AVAILABLE_ONLY_ON_DATE_TIME:
		'This experience is available only on this date and time',

	SHOW_TIMINGS: 'SHOW TIMINGS',
	PART_ONE: 'Part 1',
	PART_TWO: 'Part 2',
	DURATION: 'Duration',

	TWO_PART_SHOW: {
		SELECTED_SEATS_DESCRIPTION:
			'Selected seats are for both parts of the show',
	},

	UNAVAILABLE_ON_DATE: 'Unavailable on this date',
	GROUP_PRICE_DISPLAYED:
		'The price displayed is the total amount payable for the entire group collectively',
	GROUP_OF: 'Group of {0}',
	PRIMARY_GUEST: 'Primary guest',
	LEAD_GUEST: 'Lead guest',
	GUEST_NUMBER: 'Guest {0}',
	SELECTED: 'Selected',
	NEXT_AVAILABLE: 'Next available on',
	PREV_AVAILABLE: 'Closest availability on',

	// Long Form Content
	LFC_FALLBACK_SUFFIX: '{0} Guide',

	BOOSTERS: {
		COMBO: 'Combo',
		SELLING_OUT_FAST: 'Selling out fast',
	},

	REQUEST_TIMEOUT_TRY_AGAIN: 'Request took too long. Try again in some time.',

	// Choose Alternative Slots
	CHOOSE_ALTERNATIVE: {
		INVALID_LINK:
			'Looks like this link is invalid.\n' +
			'Please reply back to the original email with slots that work for you.',

		PLEASE_SELECT_VALID_SLOT: 'Please select a valid slot',
		SLOT_UPDATED_SUCCESS:
			'The slot has been updated successfully. You should receive your tickets shortly.',
	},

	PAGE_NO_META: ' (Page {0})',

	BANNER: {
		HEADING: "The world's best is waiting for you.",
		SUBHEADING:
			'Find exceptional real-life experiences curated by experts. Get last minute discounts, save with cashback and much more.',
	},

	BANNER_CITY: {
		HEADING: 'Best things to do in {0}',
		VIDEO_HEADING: 'Discover the best things to do in {0}',
		SUB_HEADING:
			'Find the best {0} has to offer curated by experts. Get last-minute discounts, instant tickets, cashback and so much more.',
	},

	WHO_ALL: 'Who all are coming?',
	WHAT_TIME: 'What time do you prefer?',
	SELECT_DATE_TO_PROCEED: 'Please select a date to proceed',
	SELECT_DATE: 'Select a date',

	// Cancellation page
	CANCELLATION_CONFIRMATION_TEXT:
		'Please confirm if you wish to cancel & get a refund.',

	CANCELLATION_BUTTON_TEXT: 'Cancel Booking',

	CANCELLATION_CONFIRMED_TEXT:
		'Your booking has been cancelled. You will receive an email confirming refund within the next 30 minutes.',

	EARLIER_DATE_UNAVAILABLE: 'The date selected earlier is not available',
	THINGS_TO_DO_IN_CITY: 'Things to do in {0}',
	TOP_EXPERIENCES_IN_CITY: 'Top experiences in {0}',
	IMPORTANT_INFORMATION: 'Important Information',
	POPULAR_THINGS_TO_DO: 'Popular things to do',

	PAYMENT_FAILURE_ERROR_MESSAGES: {
		'PAY-0700': 'An unknown error occurred with payment gateway',
		'PAY-0701': 'Card not found, please try a different card',
		'PAY-0740':
			'Your bank has declined this transaction. It can happen when you travel or spend higher than usual amounts. Please call your bank to whitelist the transaction or use a different card.',
		'PAY-0741':
			'Your card is not configured for this transaction. Please call your bank to whitelist the transaction or use a different card.',
		'PAY-0742':
			'Your card does not have sufficient funds. Please use a different card.',
		'PAY-0743':
			'Your CVV number was incorrect. Please try again with the correct number.',
		'PAY-0744':
			'Your card details are invalid. Please try again or use a different card.',
		'PAY-0745': `You've exceeded the withdrawal limit for this card as per your bank. Please try with a different card.`,
		'PAY-0746':
			'Your bank has declined this transaction. It can happen when you travel or spend higher than usual amounts. Please call your bank to whitelist the transaction or use a different card.',
		'PAY-0748': 'Payment Capture Failed',
		'PAY-0749': 'Payment Refund Failed',
		'PAY-0750': 'Payment Void Not Allowed',
		'PAY-0751': 'Payment Void Failed',
		'PAY-0752': `You're using an older version of the app. Please update the app or visit www.headout.com to book your tickets.`,
		'PAY-0753':
			'Payment Failed: the email ID used for this booking may be blacklisted by our payment gateway. Please try again with a different email ID.',
		'PAY-0301': 'Payment Transaction not marked risky.',
		'PAY-0302': 'Payment Transaction expired.',
		'PAY-0300': 'Currency not supported.',
	},

	INVALID_CARD_DETAILS: 'Invalid card details',
	TRANSACTION_PROCESSING: 'We are processing your transaction!',
	DO_NOT_REFRESH: 'Please do not close or refresh this page.',
	TRANSACTION_FAILED: 'Transaction failed',
	RETRY: 'Retry',

	FAILED_PAYMENT_RETRY:
		'Please try again or choose a different payment method to complete your booking',

	PAYMENT_FAILED_RETRY: 'Payment failed, please retry or use another method.',

	THREE_DS_FAILURE:
		'Please try again or choose a different payment method to complete your booking',

	MUST_DO_THINGS_IN_CITY: 'Must do things in {0}',
	SEE_TICKETS: 'See tickets',

	/* Broadway Booking Flow Select Page */
	BROADWAY_SELECT: {
		HEADERS: {
			DATE_SELECTION_HEADER: 'When do you want to go?',
			TIME_SELECTION_HEADER: 'What time do you prefer?',
			PAX_SELECTION_HEADER: `How many tickets?`,
			SEAT_SELECTION_HEADER: 'Where would you want to sit?',
		},

		BUTTON_CONFIRM: 'Confirm',
		BUTTON_PROCEED_TO_PAYMENT: 'Proceed to payment',
		BUTTON_GET_SEATS: 'Get seats',
		BUTTON_CANCEL: 'Cancel',
		BEST_AVAILABLE: 'Best Available',
		SLOT_UNAVAILABLE: 'Unavailable',

		BEST_SEAT_DISCLAIMER:
			'You will receive the best available seats for the section and price selected in this step. Tickets purchased together will be seated together.',

		COUNTDOWN_TIMER_TEXT: "We're holding your seats for",
		YOUR_SEATS: 'Your Seats',
		RELEASE_SEATS_CONFIRMATION_HEADING: 'Release Seats?',

		RELEASE_SEATS_CONFIRMATION_TEXT:
			'Modifying your order will release the seats you are currently holding. These seats will be made available to other customers.',

		RELEASE_SEATS_CONFIRM_CTA_TEXT: 'Release',
		RELEASE_SEATS_DENY_CTA_TEXT: 'Keep',
		TIMEOUT_HEADING: "Sorry, time's up!",

		TIMEOUT_TEXT:
			"We couldn't hold your seats any longer. Lets pick a slot again before your preferred seats get sold out.",

		TIMEOUT_CTA_TEXT: 'Lets go',
		SOLDOUT_HEADING: 'Sold Out',

		SOLDOUT_TEXT:
			'Your selected section is sold out, please select a different date or section and proceed. ',

		SOLDOUT_CTA_TEXT: 'Okay',

		LOADER_HEADING:
			"We're finding the best seats in the house for you, hold tight!",

		LOADER_TEXT: 'Please do not close or refresh this page.',
	},

	PARTNERS_SECTION_HEADING: 'We have the best partners',
	CANCELLATION_POLICY_HEADING: 'Cancellation policy',

	CANCELLATION_POLICY: {
		NON_CANCELLABLE_NON_RESCHEDULABLE:
			"These tickets can't be cancelled or rescheduled.",

		VALID_UNTIL_DATE:
			"These tickets can't be cancelled. However, you can use them any time until {0}.",

		VALID_WITHIN_NEXT_DAYS:
			'These tickets can’t be cancelled. However, you can use them any time within the next {0} days.',

		VALID_WITHIN_NEXT_MONTHS:
			'These tickets can’t be cancelled. However, you can use them any time within the next {0} months.',

		EXTENDED_BUT_UNKNOWN_VALIDITY:
			'These tickets can’t be cancelled. However, they are valid for an extended period of time.',

		NON_CANCELLABLE_BUT_RESCHEDULABLE:
			"These tickets can't be cancelled. However, they can be rescheduled up to {0} hours before the experience begins.",

		CANCELLABLE_HOURS:
			'You can cancel these tickets up to {0} hours before the experience begins and get a full refund.',

		CANCELLABLE_DAYS:
			'You can cancel these tickets up to {0} days before the experience begins and get a full refund.',

		NON_CANCELLABLE_BUT_RESCHEDULABLE_BEFORE_TIME:
			"These tickets can't be cancelled. However, they can be rescheduled before {0} local time on {1}.",

		CANCELLABLE_UNTIL_TIME:
			'You can cancel for free until {0} local time on {1}.',
	},

	VALIDITY: {
		HEADING: 'Ticket validity',
		UNTIL_DATE: 'These tickets are valid until {0}.',

		UNTIL_DAYS_FROM_PURCHASE:
			'These tickets are valid for {0} days from the date of purchase.',

		UNTIL_MONTHS_FROM_PURCHASE:
			'These tickets are valid for {0} months from the date of purchase.',

		EXTENDED_BUT_UNKNOWN_VALIDITY:
			'These tickets are valid for an extended duration. The exact details will be present on the ticket.',

		VALID_UNTIL: 'Valid until: {0}',
	},

	READ_MORE: 'Read more',
	GET_INSPIRED: 'Get Inspired',
	CONTINUE: 'Continue',
	PICK_ANOTHER_DATE: 'Pick another date',

	LIVE_PRICE: {
		PRICE_DECREASE: {
			TITLE: "Congratulations, you've just saved an extra {0}! 🎉",
			SUB_TEXT:
				'The price for this experience has dropped. Enjoy the savings and thank you for choosing us.',
		},
		PRICE_INCREASE: {
			TITLE: 'Attention! The price for the experience has been updated.',
			SUB_TEXT:
				'The price has gone up by {0}. This sometimes happens due to last-minute price changes by the local partner. Rest assured, this is the lowest price for your selected slot.',
		},
		DISCLAIMER: {
			DESCRIPTION:
				'The prices for this experience might change at the time of booking due to demand and seasonality.',
		},
	},

	BLOG: {
		SECTION_HEADING: 'Travel guides and tips',
		CITY_SECTION_HEADING: 'Get inspired for your {0} visit',
		CITY_EXPLORE_LOCAL_HEADING: 'Explore {0} like a local',
		BROADWAY_SECTION_HEADING: 'More About Broadway Shows',
		LTD_SECTION_HEADING: 'More About West End Shows',
		CITY_HEADING: '{0} Travel Guide',
		BROADWAY_HEADING: 'Broadway Theatre Guide',
		LTD_HEADING: 'London Theatre Guide',
		CITY_DESCRIPTION:
			'Top things to do, budget hacks, public transportation, best restaurants & hotels, culture tips & more inside!',
		BROADWAY_DESCRIPTION: "A first timer's guide to all things Broadway.",
		LTD_DESCRIPTION: "A first timer's guide to all things West End.",
		CITY_BLOG_HEADINGS: {
			ITINERARIES: 'Detailed {0} Itineraries',
			BEST_TIME_TO_VISIT: 'Best Time To Visit {0}',
			DAY_TRIPS: 'Day Trips from {0}',
			TIPS: '{0} Travel Tips',
		},
		BROADWAY_BLOG_HEADINGS: {
			SHOWS: '50+ Trending Broadway Shows',
			CHEAP_TICKET: 'How to snag cheap Broadway tickets',
			SEATING: 'Broadway Theatre seating plans',
			RESTAURANTS: '30 best restaurants on Broadway District',
		},
		LTD_BLOG_HEADINGS: {
			SHOWS: '50+ trending West End shows',
			CHEAP_TICKET: 'How to snag cheap West End tickets',
			SEATING: 'London Theatre seating plans',
			RESTAURANTS: '30 best restaurants on West End District',
		},
	},

	NEARBY_CITIES_TO_EXPLORE: 'Nearby cities to explore',
	PAYMENT_PROOF_LEAD_GUEST: 'Lead Guest',
	PAYMENT_PROOF_DATE: 'Date',
	PAYMENT_PROOF_BOOKING_ID: 'Booking ID',
	PAYMENT_PROOF_ITINERARY_ID: 'Itinerary ID',
	PAYMENT_PROOF_HEADING: 'Proof of Payment',
	PAYMENT_PROOF_TOTAL_AMOUNT_PAID: 'Total Amount Paid',
	PAYMENT_PROOF_PAYMENT_RECIVED: 'We have received the payment of',

	PAYMENT_PROOF_FOOTER_ONE:
		'The name of the local service provider or tour operator will be present on the admission ticket. The tour operator reserves the right to assign third party companies with the rights and duties under the travel contract. Headout Inc has no influence over this.',

	PAYMENT_PROOF_PLEASE_NOTE: 'Please Note:',

	PAYMENT_PROOF_FOOTER_TWO:
		'This is not an invoice for VAT purposes. Please note that this document is proof of payment only.It is not your ticket and cannot be redeemed against the experience booked.',

	PAYMENT_PROOF_HEADOUT: 'Headout Inc.',
	PAYMENT_PROOF_ADDRESS_LINE_ONE: '82 Nassau St #60351,',
	PAYMENT_PROOF_ADDRESS_LINE_TWO: 'New York, NY 10038',
	PAYMENT_PROOF_PHONE: 'Phone: +1 347 897 0100',
	PAYMENT_PROOF_EMAIL: 'support@headout.com',
	PAYMENT_PROOF_WEBSITE: 'www.headout.com',

	PAYPAL: {
		PAY_WITH: 'Pay with',
	},

	SELECT_PAYMENT_METHOD: 'Please select a payment method to proceed.',
	SIMILAR_EXPERIENCES: "Similar experiences you'd love",
	NUMBER_OF_PAYMENT_METHODS: '{0} options available',
	EACH: 'each',

	RESCHEDULE_PAGE: {
		RESCHEDULE_IN_PROGRESS: 'Reschedule in progress',
		RESCHEDULE_IN_PROGRESS_PARAGRAPH:
			'Our reservation team is on it. Expect an update within the next 1 hour. Here are your requested changes',
		RESCHEDULE_SHEET_CTA: 'Okay',
	},

	PLEASE_SELECT_DATE: 'Please select a date to continue',
	DISCOUNTED_SEATS_AVAILABLE: 'Dates with special offers',

	// ****************** Voucher Page ************************
	VOUCHER: {
		DUMMY_CALLOUT_COPY:
			'This ticket is for internal testing purpose only. Not available for use.',
		BOOKING_ID: 'BOOKING ID',
		DOWNLOAD_AND_SHOW: 'Download & show ticket',
		YOUR_TICKET: 'Your ticket',
		CONFIRMATION_NUMBER: 'Show confirmation number',
		SCAN_TICKETS: 'Scan tickets',
		CLAIM_TICKETS: 'Claim Tickets',
		DOWNLOAD_TICKETS: 'Download ticket',
		ACCESS_TRACKING_LINK: 'Access Tracking Link',
		TRACKING_LINK: 'Tracking Link',
		ACCESS_DRIVER_AND_RIDE_DETAILS:
			'Access the driver details and meeting point information here.',
		DOWNLOAD_AND_SHOW_PARAGRAPH:
			'Download and show these tickets at the venue.',
		ESCORTED_ENTRY_CALLOUT: {
			TITLE: 'Important',
			PARAGRAPH:
				'Head to your meeting point - not the venue - once you get there. Your local host will then take it from there! Scroll down for meeting point details.',
		},
		EXCHANGE_TICKET_NOTE:
			'Exchange this voucher at the venue to collect physical tickets.',
		BOOKING_DETAILS: 'Booking details',
		IMPORTANT_INSTRUCTIONS: 'Important instructions',
		CANCELLATION_POLICY: 'Cancellation policy',
		NEED_HELP: 'Need help?',
		VISIT_HELP_PAGE: 'Visit our help page',
		URGENT_CALLS: 'Urgent issues? Call us 24x7',
		SCAN_CODES: 'Scan codes',
		GRID: {
			PURCHASED_BY: 'Purchased by',
			GUEST: 'Guests',
			SEATS: 'Seats',
			EXPERIENCE_DATE: 'Experience date',
			DURATION: 'Duration',
			TIME: 'Time',
			START_TIME: 'Start time',
			CLOSING_TIME: 'Closing time',
			OPENING_TIME: 'Opening time',
			VALID_UNTIL: 'Valid until',
			LOCATION_REACH_HERE: 'Location (Reach here)',
			PICKUP_LOCATION: 'Pickup Location',
			DIRECTIONS: 'Directions',
			LOCAL_PARTNER_DETAILS: 'Local partner details',
			PARTNER_REF_NUMBER: 'Partner ref number',
		},
		COMMON_HELP_NOTE:
			'Note: If you have any questions concerning this activity or any queries regarding your reservation, please visit our',
		COMMON_HELP_CENTER: 'Help center.',
		PENDING: {
			EVENT_DETAILS: 'Event details',
			STATUS_PENDING: 'Status: Pending',
			RESERVATION_UNDER_PROGRESS:
				'The reservation is under progress. Your tickets will be emailed to you shortly.',
			KINDLY_REFRESH:
				'Kindly refresh this page in sometime to see your tickets.',
		},
		PSEUDO_OPEN_DATED: {
			HEADING: 'Book a slot for your visit',
			RESERVE_MESSAGE: {
				FIRST_HALF: 'to reserve your preferred slot at least',
				HOURS_BEFORE: 'hours before',
				SECOND_HALF: 'you plan to visit.',
			},
			CHECK_SLOT_MESSAGE: 'to check the available slots.',
			CLICK_HERE: 'Click here',
		},
		CANCEL_RESERVATION_PENDING: 'Your reservation has been Cancelled',
		TICKET_PAX_COUNT: 'Ticket {0} of {1}',
		PURCHASE_DATE: 'Purchase date',
		DISCLAIMER_HEADING: 'Disclaimer',
		PRICE: 'Price',
		DISCLAIMER_PARAGRAPH: `This ticket gives one person entry to the park(s) or the event indicated above.  It is only valid for the indicated date(s).
		A non-dated ticket does not guarantee access to the park(s) in the event that the park is crowded on that day.
		The person undertakes responsibility to comply with the Rules of Procedure of each zone posted at the entrance.
		It is prohibited to resell the ticket unless authorised.
		Furthermore, unless otherwise agreed, the tickets must not be offered or used for any advertising or promotional purposes, i.e. lottery usage.
		A ticket that does not have a Disneyland® Paris serial number will be invalid.
		EURO DISNEY ASSOCIÉS S.A.S. - SIREN 397471822 - RCS MEAUX - €2,875,978,999.40 - Licences L-D-2019-335, L-R-2022-678, 681, 684, 686, 689, 695,696, 700, 702, 707, 712, 725, 730, 731 and 4484, L-D-2022-3405, 3420, 3421, 3422 and 3454.`,
	},

	BROWSE_BY_THEMES: {
		VIEW_ALL_CATEGORY: 'View all {0}',
	},

	// ****************** Vox Audio Guide Help Page ************************
	HOW_TO_ACCESS_AUDIO_GUIDE: 'How to access the audio guide?',

	FOLLOW_INSTRUCTIONS: 'Follow these instructions to access the audio guides',
	STEP: 'Step',
	INSTALL_OUR_APP: 'Install our app',

	INSTALL_OUR_APP_DESC:
		'Download and install the Headout app on your smartphone.',

	GO_TO_ACCOUNT_PAGE: 'Go to the account page',

	GO_TO_ACCOUNT_PAGE_DESC:
		'Click on the account icon located in the app’s navigation bar.',

	LOGIN_TO_ACCOUNT: 'Log in to your account',

	LOGIN_TO_ACCOUNT_DESC:
		'Use the email address associated with your booking to log in.',

	GO_TO_AUDIO_GUIDE: 'Go to audioguides page',
	GO_TO_AUDIO_GUIDE_DESC: `On the account screen, navigate to and select ‘Audioguides’ from the menu.`,
	START_THE_GUIDE: 'Start the guide',

	START_THE_GUIDE_DESC:
		'Locate your booked experience and press the ‘play audio guide’ button.',

	HAPPY_HEADOUTING: 'Happy Headouting💜',

	WE_HOPE_YOU_ENJOY_EXPERIENCE:
		"We hope you enjoy your experience with us. Can't wait to see you again!",

	BOOK_NOW_GO_LATER_BANNER: {
		HEADER: 'We love flexibility',
		DESCRIPTION: {
			WITHOUT_VALIDITY:
				'Enjoy the freedom to book without having to pick a date',
			WITH_VALIDITY: 'Enjoy the freedom to go anytime on or before',
			TRAVEL_BEFORE: 'Travel before',
		},
	},

	B1G1: 'Buy 1 Get 1 Free',
	LESS_IS_MORE_TITLE: 'Only the finest',

	LESS_IS_MORE_TEXT:
		'At Headout, you only find the best. We do the hard work so you don’t have to.',

	GREED_IS_GOOD_TITLE: 'Greed is good',

	GREED_IS_GOOD_TEXT:
		'With quality, you also get lowest prices, last-minute availability and 24x7 support.',

	WE_DONT_JUDGE_TITLE: 'Experience every flavour',

	WE_DONT_JUDGE_TEXT:
		'Offbeat or mainstream, a tour or a show, a game or a museum - we have ‘em all.',

	NO_PAIN_ONLY_GAIN_TITLE: 'No pain, only gain',

	NO_PAIN_ONLY_GAIN_TEXT:
		'Didn’t love it? We’ll give you your money back. Not cocky, just confident.',

	HOME_PAGE_BANNER_HEADING:
		"The world's best experiences curated just for you",

	SEARCH_RESULTS: 'Search results',
	SEE_MORE_RESULTS_FOR: 'See more results for',
	COUNT_EXPERIENCES: '{0} experiences',
	SPECIAL_OFFER: 'Special Offer',

	REVIEW_LOC: {
		VIEW_ORIGINAL: 'View original review in {0}',
		VIEW_ORIGINAL_NO_LANG:
			'This review is translated. Show original review.',
		TRANSLATE: 'Translate review in {0}',
		LANGUAGES: {
			EN: 'English',
			ES: 'Spanish',
			FR: 'French',
			IT: 'Italian',
			DE: 'German',
			PT: 'Portuguese',
			NL: 'Dutch',
		},
	},

	COMBO_UPSELL: {
		SCREEN_TITLE: 'Book together and get up to',
		SCREEN_TITLE_OFF_PERCENT: '{0}% off',

		SCREEN_SUBTEXT:
			'A trip to {0}? Choose from below to create more memories while unlocking exciting discounts.',

		PRODUCT_MORE_DETAILS: 'More details',
		SELECT_A_DATE: 'Select a date',
		SELECT_A_TIME: 'Select a time slot',
		OFF_WITH_PERCENT: 'Get {0}% OFF',
		RATINGS_TEXT: '{0} ratings',

		CTA: {
			PROCEED_TO_CHECKOUT: 'Proceed to checkout',
			SKIP: "I'll skip",
			SELECT_PRODUCT: 'Select product',
		},

		MORE_DETAILS: 'More details',
		PROCEED_TO_CHECKOUT: 'Proceed to checkout',
		FAILED_TO_LOAD: 'Failed to load the page',
		TRY_AGAIN: 'Please try again or contact our support team.',
		UH_OH: 'Uh Oh!',

		INTERNET_DISCONNECTED:
			'You do not seem to be connected to the internet.',

		TRY_AGAIN_CTA: 'Try again',
		CONFIRM_SELECTION: 'Confirm Selection',
		TOTAL: 'Total',

		ERROR_MSGS: {
			ALL_MISSING:
				'Please select date, time & number of guests to continue',
			DATE_AND_PAX_MISSING:
				'Please select date & number of guests to continue',
			TIME_AND_PAX_MISSING:
				'Please select time & number of guests to continue',
			PAX_MISSING: 'Please select number of guests to continue',
		},

		SELECT_PRODUCT: 'Select experience',
		OKAY: 'Got it',
		WHO_COMING: 'Select number of guests',
		PRICE_BREAKUP: 'Price breakup',
	},

	TRAVELER_MEDIA: 'Snapshots from our guests',

	LTT: {
		SELECT_TIME: 'Select a time',
		AVAILABLE_TIME: 'Available time slot',

		EXPERIENCE_AVAILABLE_ONLY_AT:
			'This experience is available at the below time only',

		TWO_PART_SHOW: 'Two-part show',
		INTERVAL: 'Interval',
		EDIT: 'Edit',
		CLEAR_ALL: 'Clear all',
		SELECTION_CLEARED: 'Selection cleared',
		UNDO: 'Undo',
		TOTAL: 'Total',
		VIEW_SELECTED_SEATS: 'View selected seats',
		BACK_TO_SEATMAP: 'Back to the seatmap',

		SEATS_SELECTED: {
			SINGLE: '{0} seat selected',
			PLURAL: '{0} seats selected',
		},
	},

	REVIEW_SECTION: {
		HEADING: 'Millions love heading out with us',
	},

	PERSONA_AFFINITY: {
		SECTION_HEADING: '{0}, your way',
	},

	USE_COUPON_CODE: 'Use Coupon Code',
	MINIMUM_10_OFF: 'Minimum 10% off when you book on the Headout App',
	SCAN_ME: 'Scan Me',
	BOOK_IN_APP: 'Book in app',

	APP_PAGE: {
		CITY_LINE: 'Your {0} experience tickets await you',
		DOWNLOAD_APP_TO_ACCESS_TICKETS:
			'Download the app to access your tickets now!',
		ACCESS_TICKET_OFFLINE: 'Access your ticket offline',
		SCAN_TO_DOWNLOAD: 'Scan to download',
		GET_REAL_TIME_INFO: 'Get real-time updates on the go',
		GET_APP_ONLY_DEALS: 'Get app-only exclusive deals',
		AVAILABLE_ON: 'Available on',
	},

	// select page revamp
	SELECT_PAGE_REVAMP: {
		SELECT_DATE: 'Select',
		PHOTO_GALLERY: 'Photo Gallery',
		HEADOUT_GALLERY: 'Headout Gallery',
		GUEST_SNAPSHOTS: 'Guest Snapshots',
	},

	LIVE_INVENTORY: {
		SLOT_UNAVAILABLE: 'This slot is not available anymore.',
		DIFFERENT_SLOT: 'Please select a different slot.',
		CTA: 'Book another slot',
	},

	COOKIE_BANNER: {
		DESKTOP:
			'Headout uses cookies to enhance your website journey one byte at a time.',
		MOBILE: 'Cookies at work.',
		LEARN_MORE: 'Learn more',
	},

	SEARCHBAR_TEXTS: {
		SEARCH_FOR: 'Search for',
		EXPERIENCES_AND_CITIES: 'experiences and cities',
		BURJ_KHALIFA: 'Burj Khalifa',
		DUBAI: 'Dubai',
		VATICAN_MUSEUMS: 'Vatican Museums',
		ROME: 'Rome',
		EIFFEl_TOWER: 'Eiffel Tower',
		PARIS: 'Paris',
	},

	TOP_100: 'Top 100',
	ASIA: 'Asia',
	AFRICA: 'Africa',
	EUROPE: 'Europe',
	NORTH_AMERICA: 'North America',
	SOUTH_AMERICA: 'South America',
	OCEANIA: 'Oceania',
	DISCOVER_ALL_CITIES_WORLDWIDE: 'Discover all cities worldwide',
	DISCOVER_TOP_THINGS_WORLDWIDE: 'Discover top things to do worldwide',
	SECTION: 'Section',
	SELECTION_LABEL: 'The best seats together in this section',
	SELECTION_LABEL_SINGULAR: 'The best seat available in this section',
	CLICK_ON_SEATMAP: 'Tap below to view your seats',
	TICKET: 'Ticket',

	// HOHO full screen pax selection
	SELECT_GUESTS: 'Select Guests',

	ROW: 'Row',

	EXTERNAL_SEATMAP: {
		RESERVATION_FAILED:
			'The seats that you selected are no longer available. Please select different seats.',
		FETCH_FAILED:
			'Failed to fetch the seats. Please select the desired show time again.',
		LOADING_TEXT: 'Confirming your Seats',
	},

	SHARED_AIRPORT_TRANSFER: {
		ONE_WAY: 'One way',
		ROUND_TRIP: 'Round trip',
		FROM: 'From',
		TO: 'To',
		STANDARD_TICKET: 'Standard ticket',
		FIRST_CLASS: 'First class',
		SECOND_CLASS: 'Second class',
		BUSINESS_FIRST_CLASS_TICKET: 'Business first class ticket',
		DAY_TRANSFER: 'Day transfer',
		NIGHT_TRANSFER: 'Night transfer',
		TRAVEL_ANY_TIME: 'Travel anytime within operating hours',
		ONE_WAY_TRANSFER: 'One way transfer',
		ROUND_TRIP_TRANSFER: 'Round trip transfer',
		WHERE_FROM: 'Where from?',
		WHERE_TO: 'Where to?',
		TICKET_TYPE_UNAVAILABLE: 'Unavailable',
		NO_TRIPS_FOUND: 'No trips found',
		TRY_RESETTING_FILTERS: 'Try resetting filters to see results',
		RESET_FILTERS: 'Reset filters',
		READ_LESS: 'Read less',
		ONLY_ONE_TICKET_TYPE_AVAILABLE: 'Only one ticket type is available',
		ONLY_ONE_DESTINATION_AVAILABLE: 'Only one destination is available',
		ONLY_ONE_AIRPORT_AVAILABLE: 'Only one airport is available',
		SELECT_YOUR_TICKET: 'Select your ticket',
	},

	PRIVATE_AIRPORT_TRANSFER: {
		SEARCH: 'Search',
		SELECT_PICKUP: 'Select pick-up',
		FROM: 'Where from?',
		TO: 'Where to?',
		SELECT_DROPOFF: 'Select drop-off',
		ENTER_DESTINATION: 'Enter your destination',
		PICKUP_DATE: 'Pick-up date',
		ONWARD_JOURNEY: 'Onward journey',
		SELECT_DATE: 'Select date',
		PICKUP_TIME: 'Pickup time',
		SELECT_TIME: 'Select time',
		GUEST: 'Guest',
		GUESTS: 'Guests',
		ADD_GUESTS: 'Add guests',
		SHOWING_TRANSFERS_FOR: 'Showing transfers for',
		ALL_TRANSFERS_INCLUDE: 'All our transfers include',
		AIRCON_VEHICLES: 'Air-conditioned vehicles',
		FREE_HOUR_WAITING: 'Free 1-hour waiting time',
		ENG_SPEAKING_DRIVER: 'English speaking drivers',
		HOW_THIS_WORKS: 'How this works',
		BOOK_ONLINE: 'Book online',
		RECIEVE_CONFIRMATION: 'Receive confirmation and driver details',
		MEET_DRIVER: 'Meet your driver',
		ENJOY_RIDE: 'Enjoy your ride',
	},

	CREDITS_PAGE_LOGIN_SECTION: {
		ACCESS_CREDITS: 'Access your credits with Headout wallet',
		CREDITS_SECURE_WITH_EMAIL:
			'Your credits are securely stored in your Headout wallet. To check your balance log in with: ',
		CREDITS_SECURE:
			'Your credits are securely stored in your Headout wallet. Login to view your wallet balance.',
	},

	PAYABLE_PER_PERSON: 'Payable per person',

	// City SiteMap
	UPCOMING_DESTINATIONS: 'Upcoming destinations',

	// SELECT_PAGE_EXPERIMENT
	NEW_SELECT_PAGE: {
		SELECT_OPTIONS: 'Your options',
		READ_MORE: 'Read more',
		READ_LESS: 'Read less',
		STAY_INFORMED: 'Good to know',
		SINGLE_TIME: 'Available time slot',
		VISIT_ANYTIME: 'Enter anytime',
		SELECT_PAX: 'Select guests',
		GUEST: 'guest',
		GUESTS: 'guests',
		HIDE_INCLUSIONS: 'Hide inclusions',
		VIEW_INCLUSIONS: 'View inclusions',
	},

	MWEB_CHECKOUT_PAGE: {
		REVIEW_AND_PAY: 'Review & pay',
		AMOUNT_SAVED: 'Yay, you saved {0}!',
		ORDER_DETAILS: 'Order details',

		BILLABLE_CURRENCY_WARNING:
			'You are paying in {0} and the total is {1}. The total you pay could differ slightly depending on your bank’s exchange rates.',

		VIEW_MORE: 'View more',
		VIEW_LESS: 'View less',
		EMAIL_DESTINATION: 'Your tickets will be sent to {0}',
		UNAVAILABLE_OPTION: 'No option available',
		COUPON_APPLIED_SUCCESS: '{0} applied successfully',
		YAY: 'Yay!',
		WITH: 'with',
		PAY_WITH_CARD_TYPE: 'Pay with credit or debit card',
		ENTER_PROMO_CODE: 'Enter promo code',
		PROMO_CODE: 'Promo code',
	},

	RECOMMENDED_DURATION: 'Recommended duration',
	ADDRESS: 'Address',
	ALSO_KNOWN_AS: 'Also known as',
	YEAR_OPENED: 'Year opened',
	FOUNDED_BY: 'Founded by',
	VISITORS_PER_YEAR: 'Visitors per year',
	EXPECTED_WAIT_TIME_STANDARD: 'Expected wait time - Standard',
	UNESCO_STATUS: 'Unesco status',
	ARCHITECTURE_STYLE: 'Architecture style',
	PEAK_OFF_PEAK_WAIT_TIME: '{0} (Peak), {1} (Off Peak)',
	GREAT_FOR: 'Great for',
	DID_YOU_KNOW: 'Did you know?',
	ABOUT_POI_NAME: 'About {0}',
	WHY_VISIT_POI_NAME: 'Why visit {0}?',
	HIGHLIGHTS: 'Highlights',
	PRO_TIPS_TO_PICK: 'Pro tips to help you make a pick',
	PLAN_YOUR_VISIT: 'Plan your visit',
	GETTING_THERE: 'Getting there',
	ENTRANCES: 'Entrances',
	TIMINGS: 'Timings',
	AMENITIES: 'Amenities',
	ACCESSIBILITY: 'Accessibility',
	RESCTRICTED_ITEMS: 'Restricted Items',
	DRESS_CODE: 'Dress code',
	NEAREST_BUS_STOP: 'Nearest Bus stop',
	NEAREST_METRO_STATION: 'Nearest Metro station',
	NEAREST_TRAIN_STATION: 'Nearest Train station',
	NEAREST_PARKING: 'Nearest Parking',
	NEAREST_TRAM_STATION: 'Nearest Tram station',
	NOT_ALLOWED: 'Not allowed',
	GUEST_GUIDELINES: 'Guest guidelines',

	HOHO: {
		STOPS_AND_ATTRACTIONS: 'Stops & nearby attractions',
		HOP_ON_OFF_AT: 'Hop on and hop off at any of these {0} stops',
		TIMINGS_FREQUENCY: 'Timings & frequency',
		TOUR_DURATION: 'Tour duration',
		EVERY_X_MINS: 'Every {0}',
		VIEW_LOCATION: 'View Location',
		TOP_ATTRACTIONS: 'Top attractions covered',
		ROUTES_SCHEDULES: 'Routes & Schedule',

		SELECT_PREFFERED_TOUR: 'Select your preferred tour',
		SELECT_A_TOUR: 'Select a tour',
		SELECT_TOUR_TO_CONTINUE: 'Select a tour to continue',
		HOP_ON_OFF_ANYTIME: 'Hop on hop off any time between',
		SELECT_PREFFERED_TIME: 'Select your preferred time',
		SELECT_TIMESLOT: 'Select a time slot',
		SELECT_TIMESLOT_TO_CONTINUE: 'Select a time slot to continue',
		SHOW_ALL_PHOTOS: 'Show all photos',
		NEXT_ON: 'Next on',
		OFF_PERCENT: '{0}% off',

		CONTENT_TABS: {
			Inclusions: 'Inclusions',
			Routes: 'Routes',
			Details: 'Details',
			Reviews: 'Reviews',
		},

		TIMMINGS: 'Timings',
		BOARDING_POINTS: '{0} boarding points',
	},

	BROADWAY_FEE: 'Price includes a {0} service fee',

	FEE_TYPES: {
		CANCELLATION_INSURANCE_FEE: 'Cancellation insurance fee',
		CONVENIENCE_FEE: 'Convenience fee',
		HELIPORT_FEE: 'Heliport fee',
		PRIORITY_BOARDING: 'Priority boarding',
		SERVICE_CHARGE: 'Service charge',
		SERVICE_TAX: 'Service tax',
		TAX: 'Tax',
		VAT: 'VAT',
		VENDOR_SERVICE_FEE: 'Vendor service fee',
		INTREPID_MEUSEUM: 'Intrepid museum',
		SEAT_RESERVATION_FEE: 'Seat reservation fee',
		FEE: 'Booking fee',
	},

	COMMON_PAX_AVAILABILITY:
		'Sorry, you can only book up to {0} tickets for this experience.',

	PAX_AVAILABILITY:
		'Sorry, you can only book up to {0} {1} tickets for this experience.',

	RECOMMENDATIONS_PAGE: {
		DISCOUNT_REMOVAL_WARNING:
			"Are you sure? You'll miss out on extra {0}% off.",

		VIEW_DETAILS: 'View details',
		HIDE_DETAILS: 'Hide details',
		DISCOUNT_UNLOCKED_SUCCESSFULLY: 'Yay! You saved extra {0}%',
		POWERED_BY: 'powered by',
		ADD_MORE_EXPERIENCES: 'Add more experiences',
		SELECT_PREFERENCE: 'Select preference',
		EXPERIENCE_ADDED: 'Experience added',
		ADD: 'Add',
		SELECT: 'Select',
		ADD_GUESTS: 'Add guests',
		COULD_NOT_ADD_EXP: "Couldn't add experience. Try again.",
		REMOVE_EXPERIENCE: 'Remove experience',
		EXPERIENCE_REMOVED: 'Experience removed',
		REVIEW_PAY: 'One step left! Review and pay.',
		YOU_ARE_SAVING_X: 'You are saving {0} with Headout',
		ALL_DONE: 'All done! {0} awaits.',
		ADD_ON_PRODUCT_REMOVED: 'Add-on has been removed',
		REMOVE_ADD_ON: 'Remove add-on?',
		NO_LONGER_A_PART: 'The add-on will no longer be a part of your order',
		ELEVATE_YOUR_EXP_WITH: 'Elevate your experience with',
		ADD_ONS: 'add-ons',
		ADD_ONS_HEADING: 'Add-ons',
		ORDER_SUMMARY: 'ORDER SUMMARY',

		ADD_NOW_SAVE_BIG:
			'You are bound to see and do more in {0}. Add now and save big.',

		LIMITED_OFFER: 'Limited time offer, only for today',
		OFFER_DISCOUNT: 'Offer discount',
	},

	TOTAL_PAX_BROADWAY: {
		SINGULAR: 'Total for {0} tickets',
		PLURAL: 'Total for {0} tickets',
	},

	CHALLENGE_MODAL_HEADER: 'Payment verification',

	DISCOUNT: {
		CREDITS: 'Headout credits',
		PROMO: 'Promo code discount',
	},

	GOOGLE_AUTH_ERRORS: {
		SIGNIN_CANCELLED:
			'Sign-in cancelled. The login window was closed. Please try again.',
		ACCOUNT_DISABLED:
			'Your Google account is disabled or restricted. Please check your account status or try logging in with another account.',
		PERMISSION_DENIED:
			'You’ve denied the requested permissions. Please grant permissions at the time of sign-in to continue.',
	},

	USER_FIELDS: {
		SUB_HEADING_PRIMARY:
			'Booking on behalf of a friend? Enter their details.',
		SUB_HEADING_REMAINING:
			'Add the names of each guest for seamless access.',
	},
	RE_SEE_GO_WE: `Are you sure you want to leave us?`,
	SAD_TO_SEE: "We're sad to see you go!",
	ACCOUNT_DELETE_TEXT_1: `${'\u2022'} By deleting your account, you will lose your booking history, saved details, Headout credits, and any other benefits. Once deleted, these cannot be recovered, but you can always create a new account.`,
	ACCOUNT_DELETE_TEXT_2: `${'\u2022'} Any pending bookings or refunds will no longer be accessible, and Headout may delay deletion if there are unresolved issues with bookings or cancellations. Communications for active bookings and open issues will continue until they expire. For any questions, write to us at support@headout.com.`,
	ACCOUNT_DELETE_TEXT_3: `${'\u2022'} Headout may retain certain data for security, fraud prevention, legal compliance, or other legitimate reasons as required by law.`,
	PROCESSING_DELETION: 'Processing your request',
	PLEASE_PAGE_DO_CLOSE: 'Please do not close or refresh this page.',
	REQUEST_SUCCESSFUL: 'Request Successful',
	THANK_HEADING_YOU_FOR: 'Thank you for heading out with us!',
	ACCOUNT_BE_DAYS_DELETED: 'Your account will be deleted within 30 days.',
	PLEASE_NOTE_USING_SIGN:
		'Please note that if you sign in using the same email, a new account will be created for you.',
	OKAY: 'Okay',
	REQUEST_FAILED: 'Request failed',
	WERE_DELETION_INITIATE_REQUEST:
		'We were unable to initiate your deletion request due to an error. Please write to us at privacy@headout.com to initiate the deletion manually',
	DELETE_ACCOUNT: 'Delete Account',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	SETTINGS: 'Settings',

	DAY_TRIPS: {
		BANNER: {
			SUBTEXT: `Skip the stress of planning. We cover every detail - curated itinerary, comfortable transfers and expert guides. It's the best way to see the sights without the hassle.`,
		},
		WHY_WITH_HO: {
			TITLE: 'Book stress free with Headout',
			CURATED_EXPERIENCES: {
				TITLE: 'Simple decision making with carefully chosen experiences. ',
				DESCRIPTION:
					'We handle every detail from transfers to meals so you have a memorable trip.',
			},
			EXPERT_GUIDES: {
				TITLE: 'Experience the trip through the eyes of a local expert.',
				DESCRIPTION:
					'Our guides bring rich insights and memorable stories to every journey.',
			},
			FLEXI_CANCELLATION: {
				TITLE: 'Enjoy peace of mind with maximum flexibility ',
				DESCRIPTION:
					'Life happens, but we’ve got you covered with free cancellations or easy reschedule.',
			},
			GUEST_SUPPORT: {
				TITLE: '24x7 support available for you at all times',
				DESCRIPTION:
					'Travel with peace of mind knowing that our team is just a call or message away.',
			},
		},
		JUMP_LINK_ITEMS: {
			TESTIMONIALS: {
				TITLE: '{0}M+ travellers love us',
				SUBTITLE_WITH_REVIEWS: 'Read real reviews from real travellers',
				SUBTITLE_WITHOUT_REVIEWS: 'Delivering best in class experience',
			},
			HEADOUT_VERIFIED: {
				TITLE: 'Why book with us?',
				SUBTITLE: 'Hassle-free trips. Find out how.',
			},
		},
		NEAR_BY_DESTINATIONS_TITLE: 'Explore more destinations from {0}',
	},

	MANAGE_BOOKING_TITLE: 'Headout: Manage Booking Page',

	CROSS_SELL_DISCOUNT: {
		PREFIX: '{0} saved',
		SUFFIX: '{0}% discount applied!',
	},

	USER_CURRENCY_PAYABLE: 'You will pay {0}',
	CITY_BANNER_SUBTEXT: {
		PARIS: 'Stroll charming streets, savor macarons by the Seine and marvel at the sparkling Eiffel Tower.',
		DUBAI: 'Ascend Burj Khalifa, dash across golden deserts, shop in souks and unwind on Jumeirah beaches.',
		ROME: 'Toss a coin in Trevi Fountain, explore the Colosseum and indulge in gelato by the Spanish Steps.',
		SINGAPORE:
			'Wander through Marina Bay, savor street food at hawker centers and marvel at futuristic gardens.',
		BARCELONA:
			'Stroll along Las Ramblas, marvel at Sagrada Familia, eat tapas and soak up the Mediterranean sun.',
	},
	PLAN_YOUR_TRIP: 'Plan your trip',
	YOUR_TRAVEL_GUIDE_FOR_CITY: 'Your travel guide for {0}',
	TYPE_SOMETHING: 'Type something...',
	EMAIL_HAS_BEEN_SUBMITTED_SUCCESSFULLY:
		'Email has been submitted successfully',
	ERROR_SUBSCRIBING_TO_THE_NEWSLETTER: ' subscribing to the newsletter: ',
	ENTER_A_VALID_EMAIL_ID: 'Enter a valid email ID',
	SIGN_UP_FOR_THE_LATEST: 'Sign up for the latest {0} travel guides',
	GET_A_FREE_CITY_GUIDE: 'Get a FREE city guide curated from 11K+ articles',
	TRAVEL_TIPS_TO_YOUR_INBOX:
		'Travel tips, blogs, and itineraries for {0} delivered straight to your inbox.',
	HANDPICKED_ITINERARIES: 'Handpicked itineraries',
	MOST_LOVED_ITINERARIES: 'Most-loved itineraries by our guests',
	DAY_PLAN: 'DAY PLAN',
	CITY_IN_FOCUS: '{0} in focus',
	SAVE_AT_LEAST_10: 'Save at least 10%',
	TOP_OFFERS: 'Top offers',
	GUARANTEED_SAVINGS: 'Guaranteed savings',
	TOP_OFFERS_TODAY: 'Top offers today',
	DEAL_OR_NO_DEAL: 'Deal or no deal?',
	EXPLORE_CITY_LIKE_LOCAL: 'Explore {0} like a local',
	TIPS_TRICKS_AND_MORE: 'Tips, tricks, and more',
	BEST_SEATS: 'The best adjacent seats in this section',
	DETAILED_DUBAI_ITINERARY: 'Detailed {0} Itinerary',
	FEEDBACK: {
		OR: 'OR',
		PLEASE_ENTER_NAME: 'Please enter your full name',
		FILE_SIZE_UPLOAD: 'You can only upload a maximum of 3 images/videos.',
		MIN_CHARS: '*min 30 characters',
		DRAG_AND_DROP_MESSAGE:
			'Drag and drop your file or click here to upload',
		FEEDBACK_TITLE: 'Feedback',
		FEEDBACK_PLACEHOLDER: 'Tell us all your inputs in detail',
		PHOTOS: 'Photos',
		MAX_SIZE: '*max size 10MB',
		SUBMIT: 'Submit',
		ENTER_EMAIL: 'Enter your email ID',
		ENTER_NAME: 'Enter your full name',
		ENTER_BOOKING_ID: 'Enter your booking ID',
		THANKS_FEEDBACK:
			'Thanks for your feedback. It helps us curate better experiences.',
		YOUR_FEEDBACK: 'Your feedback helps us grow!',
		EMAIL_BOOKING_ERROR:
			'Please enter either your email ID or your booking ID',
		PLEASE_ENTER_FEEDBACK:
			'Please enter your feedback with a minimum of 30 characters.',
	},
	CITY_UNWRAPPED_TRAVEL_GUIDE: '{0} unwrapped: Travel guide',

	SCARCITY_BOOSTERS: {
		LIKELY_TO_SELL_OUT: {
			DATE_LEVEL: 'These dates are almost sold out - book soon!',
			SELLING_OUT_FAST: 'Selling out fast',
			THESE_TICKETS_ARE_SELLING_OUT_FAST:
				'These tickets are selling out fast!',
			CONFIRM_YOUR_TICKETS:
				'Confirm your tickets before they are sold out',
		},
		TICKETS_LEFT: {
			X_TICKETS_LEFT_GET_YOURS:
				'Only {0} tickets left—get yours before they’re gone!',
			X_TICKETS_LEFT: '{0} tickets left',
		},
	},
	TRUSTPILOT_WIDGET_CAPTION_DESKTOP:
		'Real reviews of real experiences. Trusted by explorers everywhere.',
	TRUSTPILOT_WIDGET_CAPTION_MOBILE:
		'Real reviews trusted by explorers everywhere.',
};
