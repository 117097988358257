import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { initializeGTM } from 'Server/initializeTrackingScripts';

import useAttribution from 'Hooks/useAttribution';
import useReportVitals from 'Hooks/useReportVitals';
import { trackEvent } from 'Utils/analytics';
import { getABTestingVariantBySandboxId } from 'Utils/experiments/experimentUtils';
import {
	getExternalGTMKey,
	getGTMAuth,
	getGTMKey,
	getGTMPreview,
} from 'Utils/keyUtils';
import { getSandboxID } from 'Utils/stateUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { DEVICE_TYPE, HSID_DEFAULT_VALUE } from 'Constants/constants';
import { EXPERIMENT_NAMES } from 'Constants/experiments';

const Analytics = ({ host }: any) => {
	const hsid = useSelector(state => getSandboxID(state));
	const externalGTMKey = getExternalGTMKey(host);
	useReportVitals();
	useAttribution();

	useEffect(() => {
		if (hsid && hsid !== HSID_DEFAULT_VALUE) {
			const ABTestingVariant = getABTestingVariantBySandboxId(
				EXPERIMENT_NAMES.MIXPANEL_SESSION_REPLAY,
				hsid,
				true,
			);
			trackEvent({
				eventName: ANALYTICS_EVENTS.MIXPANEL_SESSION_REPLAY,
				[ANALYTICS_PROPERTIES.IS_SESSION_RECORDED]:
					ABTestingVariant === 'Treatment' ? 'Yes' : 'No',
			});
		}
	}, [hsid]);

	return (
		<>
			{initializeGTM(
				getGTMKey(DEVICE_TYPE.COMMON),
				getGTMAuth(),
				getGTMPreview(),
			)}
			{/* @ts-expect-error TS(2554): Expected 3 arguments, but got 1. */}
			{externalGTMKey ? initializeGTM(externalGTMKey) : null}
		</>
	);
};

export default Analytics;
