import { combineUnique } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';
import type { ProductsListStoreType } from 'ReduxTypes/collections';

import { DATA_SOURCE_TYPE } from 'Constants/constants';

const initialState = {};
export const productList = (
	state: ProductsListStoreType = initialState,
	action: any,
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_PRODUCT_LIST: {
			const { cityCode, params } = action;
			const map = { isFetching: true };
			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: {
						...state?.[cityCode]?.[params],
						...map,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_LIST: {
			const {
				cityCode,
				params,
				metaData,
				nextPage,
				pageData: lastUpdatedPageInfo,
				productIdList,
				dataSource,
				receivedAt,
			} = action;
			const finalProductIdList = nextPage
				? combineUnique(
						state?.[cityCode]?.[params]?.productIdList || [],
						productIdList,
				  )
				: productIdList;
			const partialMap = {
				metaData,
				lastUpdatedPageInfo,
				productIdList: finalProductIdList,
				receivedAt,
				isFetching: !(dataSource === DATA_SOURCE_TYPE.API),
			};

			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: partialMap,
				},
			};
		}
		case ActionTypes.REQUEST_PRODUCT_LIST_BY_CITY: {
			const { cityCode, params } = action;
			const map = { isFetching: true };
			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: {
						...state?.[cityCode]?.[params],
						...map,
					},
				},
			};
		}
		case ActionTypes.REQUEST_PRODUCT_LIST_BY_COLLECTION_ID: {
			const { collectionId, params } = action;
			const map = { isFetching: true };
			return {
				...state,
				[collectionId]: {
					...state[collectionId],
					[params]: {
						...state?.[collectionId]?.[params],
						...map,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_LIST_BY_CITY: {
			const {
				cityCode,
				params,
				nextPage,
				productIdList,
				receivedAt,
				...rest
			} = action;
			const finalProductIdList = nextPage
				? combineUnique(
						state?.[cityCode]?.[params]?.productIdList || [],
						productIdList,
				  )
				: productIdList;
			const partialMap = {
				productIdList: finalProductIdList,
				receivedAt,
				...rest,
			};
			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: partialMap,
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_LIST_BY_COLLECTION_ID: {
			const {
				collectionId,
				params,
				nextPage,
				productIdList,
				receivedAt,
				...rest
			} = action;

			const finalProductIdList = nextPage
				? combineUnique(
						state?.[collectionId]?.[params]?.productIdList || [],
						productIdList,
				  )
				: productIdList;
			const partialMap = {
				productIdList: finalProductIdList,
				receivedAt,
				...rest,
			};
			return {
				...state,
				[collectionId]: {
					...state[collectionId],
					[params]: partialMap,
				},
			};
		}
		case ActionTypes.RECEIVE_RECENT_PRODUCT_LIST: {
			const { cityCode, params, productIdList, receivedAt } = action;

			const partialMap = {
				productIdList,
				receivedAt,
				isFetching: false,
			};

			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: partialMap,
				},
			};
		}
		default:
			return state;
	}
};
