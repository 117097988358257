import { ActionTypes } from 'Actions/actions';

export const domainConfig = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_DOMAIN_CONFIG: {
			return { ...state, ...action.payload };
		}
		case ActionTypes.REQUEST_GUEST_COUNT: {
			return state;
		}
		case ActionTypes.RECEIVE_GUEST_COUNT: {
			return {
				...state,
				guestCount: {
					totalServed: action.payload.totalServed,
				},
			};
		}
		default:
			return state;
	}
};
