import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import classNames from 'classnames';

import useABTesting from 'Hooks/useABTesting';
import { isBookingFlowPage } from 'Utils/bookingFlowUtils';
import {
	shouldResolveTofuExperiment,
	showRevampedCityPageVariant,
} from 'Utils/cityUtils';
import { deseparatelyGetDomEl } from 'Utils/gen';
import PlatformUtils from 'Utils/platformUtils';
import { getCurrentCityCode, isBot } from 'Utils/stateUtils';
import {
	doesCurrentRouteIncludeString,
	getLocationObjectFromRouter,
	getSourceFromUrl,
	isCityPageUrl,
	isExperiencePageUrl,
	isHomePageUrl,
} from 'Utils/urlUtils';

import {
	CITY_PAGE_CONTENT_START_ID,
	EXPERIENCE_PAGE_CONTENT_START_ID,
	HOME_PAGE_CONTENT_START_ID,
} from 'Constants/constants';
import { EXPERIMENT_NAMES, VARIANTS } from 'Constants/experiments';

const DesktopHeader = dynamic(() => import('Containers/desktop/header'));
const StickyHeaderFooter = dynamic(
	() =>
		import(
			/* webpackChunkName: 'StickyHeaderFooter' */ 'Containers/mobile/stickyHeaderFooter'
		),
);

const shouldShowStickyHeaderFooter = (location: any) =>
	getSourceFromUrl(location) !== 'ios_app' &&
	getSourceFromUrl(location) !== 'android_app' &&
	!doesCurrentRouteIncludeString('/profile/booking', location);

const shouldHideDesktopHeader = ({ currentPage, location }: any) =>
	(isBookingFlowPage({ currentPage, location }) &&
		!doesCurrentRouteIncludeString('/confirmation', location) &&
		!doesCurrentRouteIncludeString('/choose-alternative', location)) ||
	doesCurrentRouteIncludeString('/book/', location) ||
	doesCurrentRouteIncludeString('post-covid', location) ||
	doesCurrentRouteIncludeString(
		'global-travel-reopening-tracker',
		location,
	) ||
	doesCurrentRouteIncludeString('supply-partner', location) ||
	doesCurrentRouteIncludeString('/payment/payment-proof', location);

const getOptions = (isDesktop: boolean) => ({
	rootMargin: isDesktop ? '-130px 0px 0px 0px' : '-30px 0px 0px 0px',
});

const CommonHeader = (props: any) => {
	const {
		router: { query, pathname, asPath },
		currentPage,
	} = props;
	const cityCode = useSelector(state => getCurrentCityCode(state));
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const { lang = 'en' } = query;
	const isUser = useSelector(state => !isBot(state));

	const cityPageVariant = useABTesting({
		shouldResolveVariant: shouldResolveTofuExperiment({
			isUser,
			pageType: currentPage,
			cityCode,
			paramLang: lang,
		}),
		EXPERIMENT_TYPE: EXPERIMENT_NAMES.TOFU_CITY_PAGE,
		noTrack: true,
	});

	const showRevampedCityPage = showRevampedCityPageVariant({
		isUser,
		pageType: currentPage,
		cityCode,
		paramLang: lang,
		cityPageVariant,
	});

	const isHomePage = isHomePageUrl(location.pathname);
	const isExperiencePage = isExperiencePageUrl(location.pathname);
	const isCityPage = isCityPageUrl(location.pathname);
	const isCityPageRevamped = isCityPage && showRevampedCityPage;
	const [transparentBar, setTransparentBar] = useState(
		isHomePage || isExperiencePage || isCityPageRevamped,
	);
	const [isAtTop, setIsAtTop] = useState(true);

	const isDesktop = PlatformUtils.isDesktop();

	useEffect(() => {
		if (!isHomePage && !isExperiencePage && !isCityPageRevamped) return;
		setTransparentBar(true);
		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setTransparentBar(true);
			} else {
				const eleY = entry.boundingClientRect.y;
				const windowHeight = window.innerHeight;
				setTransparentBar(
					(isHomePage && eleY === 0) || eleY > windowHeight,
				);
			}
		}, getOptions(isDesktop));

		const contentID = isHomePage
			? HOME_PAGE_CONTENT_START_ID
			: isExperiencePage
			? EXPERIENCE_PAGE_CONTENT_START_ID
			: CITY_PAGE_CONTENT_START_ID;

		deseparatelyGetDomEl('#' + contentID, 6, 300)
			.then(domEl => {
				observer.observe(domEl as Element);
			})
			// eslint-disable-next-line no-console
			.catch(e => console.error(e));

		return () => {
			observer.disconnect();
		};
	}, [isHomePage, isExperiencePage, isCityPageRevamped]);

	useEffect(() => {
		const checkIfAtTop = () => {
			if (window.scrollY <= 0) {
				setIsAtTop(true);
			} else if (window.scrollY > 0 && isAtTop) {
				setIsAtTop(false);
			}
		};
		window.addEventListener('scroll', checkIfAtTop);

		return () => window.removeEventListener('scroll', checkIfAtTop);
	}, []);

	const desktopHeader = !shouldHideDesktopHeader({
		currentPage,
		location,
	}) && (
		<DesktopHeader
			key='desktop-header'
			location={location}
			paramLang={lang}
			transparentBar={transparentBar}
			isAtTop={isAtTop}
		/>
	);

	const mobileHeader = (
		<StickyHeaderFooter
			key='mobile-header'
			show={shouldShowStickyHeaderFooter(location)}
			// @ts-expect-error TS(2322): Type '{ key: string; show: boolean; location: { qu... Remove this comment to see the full error message
			location={location}
			transparentBar={transparentBar}
			headerClassName={classNames({
				'header--transparent': transparentBar,
				'revamped-header-container': isCityPageRevamped,
			})}
			isCityPageRevamped={isCityPageRevamped}
			isAtTop={isAtTop}
		/>
	);

	return <>{isDesktop ? desktopHeader : mobileHeader}</>;
};

export default withRouter(CommonHeader);
