'use client';

import type { ReactNode } from 'react';
import React from 'react';

import { css, cx } from '@headout/pixie/css';

import Conditional from 'Components/common/conditional';

import { CloseIcon } from 'Assets/svg/checkout/CloseIcon';

import { MODAL_CLOSE_REASON } from 'Constants/constants';

const coreModalContainer = css({
	alignItems: 'center',
	background: 'rgba(0, 0, 0, 0.5)',
	WebkitBoxSizing: 'border-box',
	MozBoxSizing: 'border-box',
	boxSizing: 'border-box',
	display: 'flex',
	height: '100%',
	justifyContent: 'center',
	left: '0',
	opacity: 1,
	position: 'fixed',
	top: '0',
	WebkitTransition: 'all 0.2s ease',
	MozTransition: 'all 0.2s ease',
	msTransition: 'all 0.2s ease',
	OTransition: 'all 0.2s ease',
	width: '100%',
	zIndex: 999,

	'& .right-end-modal': {
		alignItems: 'flex-start !important',
		justifyContent: 'flex-end !important',
	},

	'& .core-modal-content': {
		alignItems: 'center',
		background: '#fff',
		// @ts-expect-error
		WebkitBoxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
		MozBoxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
		boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
		WebkitBoxSizing: 'border-box',
		MozBoxSizing: 'border-box',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '50%',
		minWidth: '80%',
		overflow: 'auto',
		position: 'relative',
	},

	'& .core-modal-content.display-block': {
		display: 'block',
	},

	'& .core-modal-content.fixed-width': {
		minWidth: 'auto',
		width: '23.4375rem',
	},

	'& .core-modal-content.large-fixed-width': {
		minWidth: 'auto',
		width: '37.5rem',
	},

	'& .core-modal-content.full-width': {
		minWidth: 'auto',
		width: '100vw',
	},

	'& .core-modal-content.full-width-height': {
		height: '100vh',
		minWidth: 'auto',
		width: '100vw',
	},

	'& .core-modal-hide': {
		height: '0',
		opacity: 0,
		top: '50%',
	},
});

const closeButton = css({
	height: '1.5rem',
	width: '1.5rem',
	zIndex: 2,
	position: 'absolute',
	top: '1.5rem',
	right: '0.75rem',
	margin: '0.5rem',
	cursor: 'pointer',
});

type OwnProps = {
	containerClassName?: string;
	containerHideClassName?: string;
	contentClassName?: string;
	open?: boolean;
	showCloseButton?: boolean;
	onRequestClose?: (...args: any[]) => any;
	children?: ReactNode;
};

/**
 * This is a core modal component
 * The show/hide animation styles can be overridden via a combination of
 * "containerClassName" and "containerHideClassName"
 */
class Modal extends React.Component<OwnProps> {
	static defaultProps = {
		containerClassName: '',
		containerHideClassName: '',
		contentClassName: '',
	};

	componentDidMount() {
		this.toggleBodyOverflow(this.props.open);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	UNSAFE_componentWillReceiveProps(nextProps: any) {
		this.toggleBodyOverflow(nextProps.open);
	}

	componentWillUnmount() {
		this.toggleBodyOverflow(false);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	bodyHasOverflowHidden() {
		return window.document.body.className.includes('overflow-hidden');
	}

	handleKeyDown = (event: KeyboardEvent) => {
		const { open, onRequestClose } = this.props;
		if (event.key === 'Escape' && open && onRequestClose) {
			onRequestClose();
		}
	};

	// Prevents scrolling background
	toggleBodyOverflow = (isOpen: any) => {
		if (isOpen === true && !this.bodyHasOverflowHidden()) {
			window.document.body.className +=
				window.document.body.className === ''
					? 'overflow-hidden'
					: ' overflow-hidden';
		} else if (!isOpen) {
			window.document.body.className =
				window.document.body.className.replace(
					/(?:\soverflow-hidden|overflow-hidden\s|^overflow-hidden)/,
					'',
				);
		}
	};

	render() {
		const {
			open,
			containerClassName,
			containerHideClassName,
			contentClassName,
			onRequestClose,
			children,
			// @ts-expect-error TS(2339): Property 'preserveContent' does not exist on type ... Remove this comment to see the full error message
			preserveContent,
			// @ts-expect-error TS(2339): Property 'dimensionsStyle' does not exist on type ... Remove this comment to see the full error message
			dimensionsStyle,
			showCloseButton,
		} = this.props;
		return preserveContent || open ? (
			<div
				className={cx(
					coreModalContainer,
					'core-modal-container',
					containerClassName,
					!open ? 'core-modal-hide' : '',
					!open ? containerHideClassName : '',
				)}
				onClick={e => {
					e.preventDefault();
					if (onRequestClose) {
						onRequestClose(MODAL_CLOSE_REASON.CLICK_OUTSIDE, e);
					}
				}}
			>
				<div
					className={`core-modal-content ${contentClassName}`}
					onClick={e => {
						// need both as one was not enough
						e.stopPropagation();
						e.preventDefault();
					}}
					style={dimensionsStyle}
				>
					<Conditional if={showCloseButton}>
						<div
							className={cx(closeButton, 'modal-close-button')}
							onClick={() =>
								onRequestClose?.(
									MODAL_CLOSE_REASON.CLOSE_BUTTON,
								)
							}
						>
							<CloseIcon />
						</div>
					</Conditional>
					{children}
				</div>
			</div>
		) : null;
	}
}

export default Modal;
