import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { getABTestingVariantBySandboxId } from 'Utils/experiments/experimentUtils';
import { getSandboxID } from 'Utils/stateUtils';
import { getQueryObject } from 'Utils/urlUtils';

import { COOKIE, HSID_DEFAULT_VALUE } from 'Constants/constants';
import { EXPERIMENTS } from 'Constants/experiments';

/**
 * Hook to get A/B Testing variant for `EXPERIMENT_TYPE` _(see - src/constants/experiments.js)_
 */
type Props = {
	shouldResolveVariant?: boolean;
	EXPERIMENT_TYPE: string;
	noTrack?: boolean;
	additionalEventProps?: Record<string, any>;
};
const useABTesting = ({
	shouldResolveVariant = true,
	EXPERIMENT_TYPE,
	noTrack = false,
	additionalEventProps,
}: Props) => {
	const experimentObject =
		EXPERIMENTS[EXPERIMENT_TYPE as keyof typeof EXPERIMENTS];

	const experimentOverride =
		typeof window !== 'undefined'
			? getQueryObject(window?.location)?.[COOKIE.EXPERIMENT_OVERRIDE] ??
			  Cookies.get('experimentOverride')
			: null;
	const experimentOverrideVariant =
		typeof experimentOverride === 'string' ? experimentOverride : null;

	/**
	 * identifies and avoids experiment resolution flow for full rollout, [100, 0]/[0, 100] cases.
	 */
	const fullRolloutIndex = experimentObject?.bucketWeight.indexOf(100);
	const fullRolloutVariant =
		fullRolloutIndex !== -1 && shouldResolveVariant
			? experimentObject?.bucketName[fullRolloutIndex]
			: '';
	const [variant, setVariant] = React.useState<string | null>(
		experimentOverrideVariant || fullRolloutVariant || null,
	);

	const { sandboxId } = useSelector(state => {
		return {
			sandboxId: getSandboxID(state),
		};
	});
	useEffect(() => {
		if (
			!shouldResolveVariant ||
			sandboxId === HSID_DEFAULT_VALUE ||
			variant !== null
		)
			return;

		const abTestingVariant = getABTestingVariantBySandboxId(
			EXPERIMENT_TYPE as keyof typeof EXPERIMENTS,
			sandboxId,
			noTrack,
			additionalEventProps,
		);

		setVariant(abTestingVariant);
	}, [
		EXPERIMENT_TYPE,
		noTrack,
		sandboxId,
		additionalEventProps,
		shouldResolveVariant,
		variant,
	]);

	return variant;
};

export default useABTesting;
