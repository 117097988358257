import * as React from 'react';

import { css, cva } from '@headout/pixie/css';

import {
	FbLogoFilledSvg,
	InstagramLogoFilledSvg,
	LinkedInLogoFilledSvg,
	XLogoFilledSvg,
	YoutubeLogoFilledSvg,
} from 'Assets/svg/social';

import { SOCIAL_LINKS } from 'Constants/constants';

const styledSocialLinks = css({
	display: 'flex',
	justifyContent: 'flex-start',
	gap: '0.1875rem',
	alignItems: 'center',
});

const socialIcon = cva({
	base: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 28,
		height: 28,
	},
	variants: {
		background: {
			light: {
				'& svg': {
					color: 'semantic.text.grey.3',

					_hover: {
						fill: 'semantic.icon.primary',
					},
				},
			},
			dark: {
				'& svg': {
					color: 'semantic.surface.light.grey.2',

					_hover: {
						fill: 'semantic.text.white',
					},
				},
			},
		},
	},
});

const SocialMedia = ({
	isDarkBg = false,
	currentCityCode,
}: {
	isDarkBg?: boolean;
	currentCityCode?: string;
}) => {
	const SOCIAL_DETAILS = [
		{
			href: SOCIAL_LINKS.LINKEDIN_URL,
			icon: <LinkedInLogoFilledSvg key={'linkedin'} />,
		},
		{
			href:
				currentCityCode === 'DUBAI'
					? SOCIAL_LINKS.INSTAGRAM_HEADOUT_DUBAI_URL
					: SOCIAL_LINKS.INSTAGRAM_HEADOUT_URL,
			icon: <InstagramLogoFilledSvg key={'instagram'} />,
		},
		{
			href: SOCIAL_LINKS.YOUTUBE_URL,
			icon: <YoutubeLogoFilledSvg key={'youtube'} />,
		},
		{
			href: SOCIAL_LINKS.FB_URL,
			icon: <FbLogoFilledSvg key={'facebook'} />,
		},
		{
			href: SOCIAL_LINKS.TWITTER_URL,
			icon: <XLogoFilledSvg key={'twitter'} />,
		},
	];

	return (
		<ul className={styledSocialLinks}>
			{SOCIAL_DETAILS.map(item => {
				return (
					<li
						key={item.icon.key}
						className={socialIcon({
							background: isDarkBg ? 'dark' : 'light',
						})}
					>
						<a
							href={item.href}
							target='_blank'
							rel='noreferrer noopener'
						>
							{item.icon}
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default SocialMedia;
