import { ActionTypes } from 'Actions/actions';

export const requestGuestCount = () => ({
	type: ActionTypes.REQUEST_GUEST_COUNT,
});

export const receivedGuestCount = (payload: { totalServed: string }) => ({
	payload,
	type: ActionTypes.RECEIVE_GUEST_COUNT,
});
